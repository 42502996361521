import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  officesPTUrl,
  imgOfficeUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import { BsDownload } from "react-icons/bs";

const TermOfService = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [userId, setUserId] = useState(null);
  const [userAccount, setUserAccount] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);
  const [policyData, setPolicyData] = useState({});
  const [termData, setTermData] = useState({});
  const [dataTApp, setTDataApp] = useState([]);
  //readTByType.php
  //readPByType.php
  function readPolicyData(type) {
    axios
      .get(`${officesPTUrl}readPByType.php/${type}`)
      .then(function (response) {
        console.log("Policy: ", response.data);
        setPolicyData(response.data);
      });
  }
  function readTermData(type) {
    axios
      .get(`${officesPTUrl}readTByType.php/${type}`)
      .then(function (response) {
        console.log(response.data);
        setTermData(response.data);
      });
  }

  const readAccount = (id) => {
    axios.get(`${pvuserUrl}readuser.php/${id}/pvacc`).then(function (response) {
      // console.log(response.data);
      setUserAccount(response.data);
    });
  };

  useEffect(() => {
    var id = localStorage.getItem("pvid");
    // var user = localStorage.getItem("ufullname");
    setUserId(id);
    readAccount(id);
    readPolicyData(1);
    readTermData(1);
  }, []);
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem]">
          <div className="text-sm flex justify-end">
            {policyData?.urlfiledownload ? (
              <>
                <a
                  href={`${imgOfficeUrl}${termData?.urlfiledownload}`}
                  target="_blank"
                  className="bg-blue-500 hover:bg-blue-700 text-white border rounded w-[150px] py-1 pl-2 flex gap-2 items-center"
                >
                  <BsDownload size={22} /> Download pdf
                </a>
              </>
            ) : (
              <>
                <span className="bg-blue-300 hover:bg-blue-500 text-white border rounded w-[150px] py-1 pl-2 flex gap-2 items-center">
                  <BsDownload size={22} /> Download pdf
                </span>
              </>
            )}
          </div>
          {/* ---------  */}
          <strong className="p-2 font-medium">เงื่อนไขและข้อกำหนด</strong>
          <div className="flex justify-center items-center mt-[1rem] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1">
            <p
              className="mb-4 text-sm"
              dangerouslySetInnerHTML={{
                __html: termData?.content,
              }}
            ></p>
          </div>
          {/* ---------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default TermOfService;
