import React, { useState, useEffect, useRef, useContext } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, HeaderMap } from "../components";
import axios from "axios";
import { UserLocationContext } from "../context/UserLocationContext";

const UserLocation = () => {
  // const { id,  } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();
  // const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  // const [currentLocation, setCurrentLocation] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  // const { location } = useContext(UserLocationContext);
  const { location, setLocation } = useContext(UserLocationContext);
  // const { location, setLocation } = useContext(UserLocationContext);
  /*   let longdo;
  let map; */

  /*   const mapCallback = () => {
    longdo = window.longdo;
    map = new window.longdo.Map({
      placeholder: document.getElementById("longdo-map"),
      language: "en",
    });
  }; */

  /*   const initMap = () => {
    map.Layers.setBase(longdo.Layers.GRAY);
  };
 */
  const longdo_key = "9820e2a8e06a7cf53d8521d1517578dd";
  // const mapRef = useRef();

  var map;
  var marker;
  const mapCallback = () => {
    console.log("mapCallback");
    // console.log("Location mapCallback: ", location);
    marker = new window.longdo.Marker({
      lon: location?.longitude,
      lat: location?.latitude,
    });
    map = new window.longdo.Map({
      zoom: 12,
      location: {
        lon: location?.longitude,
        lat: location?.latitude,
      },
      placeholder: document.getElementById("longdo-map"),
      lastView: false,
    });
    map.Overlays.add(marker);
  };
  /*   const initMap = () => {language: "en",
    map.Layers.setBase(longdo.Layers.GRAY);
  };
 */
  /*   useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCurrentLocation({ latitude, longitude });
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      });
    } else {
      console.log("Geolocation not supported");
    }
  }, [currentLocation]);  */
  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");

    /*     if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://api.longdo.com/map/?key=${longdo_key}`;
      script.id = "longdoMapScript";
      document.body.appendChild(script);

      script.onload = () => {
        mapCallback();
        console.log(map);
      };
    } */

    if (existingScript) mapCallback();

    /*     const script = document.createElement("script");

    script.src = `https://api.longdo.com/map3/?key=${longdo_key}`;
    script.async = true;

    document.body.appendChild(script);
    if (typeof window === "object") {
      // Check if document is finally loaded
      document.addEventListener("DOMContentLoaded", function () {
        // *** VANILLA JAVASCRIPT CODE FOR THE IMAGE GALLERIE ***
        console.log("Now");
      });
    }

    return () => {
      document.body.removeChild(script);
    }; */
    // if (existingScript && callback) callback();
    // initMap();
    // alert("Hello");
    // const handleLoad = () => {
    // Perform actions after the component has fully loaded
    // };
    /*     window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("load", handleLoad);
    };
    const divElement = mapRef.current;
    console.log(divElement); */
  }, []);
  // console.log("Location: ", location);
  console.log("MY PROVIDER LOCATION: ", location);
  return (
    <>
      <HeaderMap />
      {/* <div>{JSON.stringify(currentLocation)}</div> */}
      <div className="overflow-y-scroll">
        {/* -------  */}
        <div className="mt-[4.5rem]">
          <div
            className={`w-[${windowSize[0]}px] h-[${windowSize[1]}px] overflow-hidden mb-1`}
          >
            {/* <LongdoMap id="longdo-map" mapKey={longdo_key} callback={initMap} /> */}
            <div
              id="longdo-map"
              className={`w-[${windowSize[0]}px] h-[600px] text-black`}
            ></div>
            <div>{JSON.stringify(location)}</div>
            {/* <LongdoMap id={"longdo-map"} mapKey={longdo_key} callback={initMap} /> */}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default UserLocation;
