import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgUserUrl,
  shopUrl,
  imgPvUrl,
} from "../constants/BaseUrl";
import { HeaderBack } from "../components";
import axios from "axios";

const UserProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [userAccount, setUserAccount] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  const readAccount = (id) => {
    axios
      .get(`${shopUrl}readuserlogin.php/${id}/pvacc`)
      .then(function (response) {
        // console.log("Member: ", response.data);
        setUserAccount(response.data);
        // readApi(response.data.country);
      });
  };
  useEffect(() => {
    var user = localStorage.getItem("pvid");
    setUserId(user);
    readAccount(user);
    /*     if (user === null) {
          navigate("/signin");
        } */
  }, []);
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* ------- windowSize */}

        <div className="mt-[6rem]">
          <div
            className={`w-[${windowSize[0]}px] h-[${
              windowSize[0] * 0.5
            }px] rounded-lg border-[1.5px] border-stroke border-[rgb(241 245 249)] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden`}
          >
            {/* ----------  */}
            <div className="flex flex-row gap-4 py-2">
              <div className="w-[80px] h-[80px] rounded-full overflow-hidden mb-1">
                {userAccount?.picture !== null ? (
                  <>
                    <img
                      src={`${imgPvUrl}${userAccount.picture}`}
                      // src={require("../assets/images/person_color.png")}
                      alt={userAccount?.picture}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={require("../assets/images/person_color.png")}
                      alt={userAccount?.picture}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                )}
              </div>
              <div className="flex-1 w-full">
                {/*             {item?.province ? (
          <>
            <strong className="text-sm font-medium">
              {item?.province}
            </strong>
            <br />
          </>
        ) : null} */}
                <strong className="block text-sm font-medium">
                  {userAccount?.firstname !== ""
                    ? `${userAccount?.firstname} ${userAccount?.lastname}`
                    : "ไม่ระบุชื่อสกุล"}
                </strong>
                <strong className="text-sm font-medium">
                  {" "}
                  อีเมล์: {userAccount?.email}
                </strong>
                <span className="block text-xs">
                  โทรศัพท์: {userAccount?.phone}-{userId}
                </span>
                {/*                 <div className="text-sm text-right w-full">
              <button
                onClick={() => {}}
                className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
              >
                แก้ไขโปรไฟล์
              </button>
            </div> */}
              </div>
            </div>
            <div className="flex flex-row gap-4 justify-end">
              <Link
                to={"/editprofile"}
                className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
              >
                แก้ไขโปรไฟล์
              </Link>
              {/*               <button
            onClick={() => {}}
            className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
          >
            แก้ไขโปรไฟล์
          </button> */}
            </div>
            {/* ----------  */}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default UserProfile;
