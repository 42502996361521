import React from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { HiMenuAlt1, HiChevronLeft } from "react-icons/hi";
import { BiHomeSmile } from "react-icons/bi";

const HeaderMap = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 w-full mx-auto z-50 pt-3 pb-3 px-2 bgDark overflow-hidden flex justify-between items-center  md:w-[24.375rem] mb-1">
      <button
        onClick={() => navigate(-1)}
        className="cursor-pointer bg-sky-500 rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]"
      >
        <HiChevronLeft fontSize={30} color="#FFF" />
      </button>
      <div>
        <span className="text-sky-500 font-medium text-2xl">Gift</span>Likes
      </div>
      <div className="bg-sky-500 w-[2.75rem] h-[2.75rem] rounded-full block flex items-center justify-center">
        <Link to={"/"}>
          <BiHomeSmile size={30} color="#FFF" />
        </Link>
        {/*         <img
        src="https://source.unsplash.com/200x200?face"
        className="rounded-full w-full h-full object-cover"
      /> */}
      </div>
    </div>
  );
};

export default HeaderMap;
