import React from "react";

const PQCarddetail = ({ data, width }) => {
  let total = 0; //
  return (
    <div>
      {data.map((item, idx) => {
        total += item?.qty * item?.price;
        return (
          <div
            key={idx}
            className={`w-[${width[0]}px] flex justify-between rounded-sm rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden`}
          >
            <div>{Number(idx) + 1}</div>
            <p>{item?.servicename}</p>
            <div> {item?.qty}</div>
            <p>{item?.price}</p>
            <p>{Number(item?.qty) * Number(item?.price)}</p>
          </div>
        );
      })}
      <div className="flex justify-end rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1">
        <strong>{total}</strong>
      </div>
    </div>
  );
};

export default PQCarddetail;
