import React, { useEffect } from "react";
import { MdOutlineBookmarkAdd, MdOutlineCategory } from "react-icons/md";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { CgShoppingBag } from "react-icons/cg";
import { GrHomeRounded } from "react-icons/gr";
import { BiHomeSmile } from "react-icons/bi";
import {
  IoSettings,
  IoSettingsOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  useEffect(() => {
    // Clear the state after
    navigate(location.pathname, { replace: true });
    // const { pathname } = location;
  }, []); //navigate

  console.log(pathname);
  const buttonActiveClass =
    "bg-black bg-gradient-to-r from-cyan-500 to-blue-500 text-white flex justify-center items-center w-[2.75rem] h-[2.75rem] rounded-full shadow-[0px_4px_10px_rgba(0,0,0,0.25)]";
  const buttonInactiveClass =
    "bg-gray-50 bg-gradient-to-t from-[#f1f1f1] to-gray-50 text-black flex justify-center items-center w-[2.75rem] h-[2.75rem] rounded-full";
  const centerClass = "h-[3.5rem] w-[3.5rem] flex items-center  justify-center";
  return (
    <div className="flex items-center justify-center fixed bottom-0 mx-auto w-full h-[5.5rem] bgDark shadow-[0px_-4px_14px_rgba(0,0,0,0.04)] md:w-[24.375rem]">
      <div className="flex gap-10">
        <NavLink
          to={"/"}
          className={({ isActive }) =>
            isActive ? buttonActiveClass : buttonInactiveClass
          }
          /*           className={`${
            pathname.includes("/") ? buttonActiveClass : buttonInactiveClass
          } ${centerClass}`} */
          /*           className={({ isActive, isPending }) =>
            isPending ? "" : isActive ? buttonActiveClass : buttonInactiveClass
          } */
        >
          <BiHomeSmile fontSize={26} className="" />
        </NavLink>
        <NavLink
          to={"/homeservices"}
          className={({ isActive }) =>
            isActive ? buttonActiveClass : buttonInactiveClass
          }
          /*           className={`${
            pathname.includes("homeservices")
              ? buttonActiveClass
              : buttonInactiveClass
          } ${centerClass}`} */
        >
          <FaRegUser fontSize={26} className="" />
        </NavLink>
        <NavLink
          to={"/homecontact"}
          className={({ isActive }) =>
            isActive ? buttonActiveClass : buttonInactiveClass
          }
          /*           className={`${
            pathname.includes("homesettings")
              ? buttonActiveClass
              : buttonInactiveClass
          } ${centerClass}`} */
        >
          <IoNotificationsOutline fontSize={26} className="" />
        </NavLink>
        <NavLink
          to={"/homesettings"}
          className={({ isActive }) =>
            isActive ? buttonActiveClass : buttonInactiveClass
          }
          /*           className={`${
            pathname.includes("homecontact")
              ? buttonActiveClass
              : buttonInactiveClass
          } ${centerClass}`} */
        >
          <IoSettingsOutline fontSize={26} className="" />
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
