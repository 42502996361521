import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgUserUrl,
  shopUrl,
  imgPvUrl,
  loginUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import { IoImagesSharp } from "react-icons/io5";
import Swal from "sweetalert2";

const EditProfile = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [userAccount, setUserAccount] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      // setFileName(URL.createObjectURL(e.target.files[0]));
      setFileName(URL.createObjectURL(e.target.files[0])); //.name
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };
  const readAccount = (id) => {
    axios.get(`${shopUrl}readuserlogin.php/${id}`).then(function (response) {
      // console.log(response.data);
      setUserAccount(response.data);
      setFirstname(response.data.firstname);
      setProfileImg(response.data.picture);
      setLastname(response.data.lastname);
      setPhone(response.data.phone);
      window.localStorage.setItem("pvispolicy", response.data.ispolicy);
      window.localStorage.setItem(
        "pvfullname",
        response.data.firstname + " " + response.data.lastname
      );
    });
  };

  useEffect(() => {
    var id = localStorage.getItem("pvid");
    var user = localStorage.getItem("pvfullname");
    /*     if (user === null) {
      navigate("/signin");
    } */
    console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    readAccount(id);
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedImage);
    formData.append("memId", userId);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("phone", phone);
    setIsLoading(true);
    //laborlogo id,title,photo,discription,url,created_at
    //updateskilllaborlogo.php
    axios
      .post(`${loginUrl}editprofile.php`, formData)
      .then(function (response) {
        // console.log(response.data);
        // readData(id);
        readAccount(userId);
        setSelectedImage(null);
        setFileName(null);
        setProfileImg(null);
        setFirstname("");
        setLastname();
        setPhone();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
        // reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setIsLoading(false); // Stop loading
        //locations
      });
  };

  // console.log("Del Acc: ", userAccount);
  // console.log("File Name: ", fileName);
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-4">
        {/* ------- profileImg */}

        <div className="mt-[6rem]">
          <div className="w-full flex justify-center">
            {profileImg !== null ? (
              <img
                src={`${imgPvUrl}${profileImg}`}
                alt={profileImg}
                className={`w-[100px] h-[100px] rounded-full object-cover`}
              />
            ) : null}
          </div>
          <div
            className={`w-[${windowSize[0]}px] h-[${windowSize[1]}px] p-2 overflow-hidden mb-1`}
          >
            {/* ----------- onSubmit={handleSubmit(handleOnSubmit)} shadow-[0px_4px_14px_rgba(0,0,0,0.04)]  */}
            <form onSubmit={handleOnSubmit}>
              <div className="mb-2 flex flex-col gap-2 md:flex-row">
                <div className="mb-2 flex-1">
                  <label>ชื่อจริง</label>
                  <input
                    type="text"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                {/* //firstname,lastname,phone,email,password,pltype */}
                <div className="mb-2 flex-1">
                  <label>นามสกุล</label>
                  <input
                    type="text"
                    name="lastname"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label>โทรศัพท์</label>
                <input
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-4.5 flex flex-col gap-6 md:flex-row">
                <div className="mb-2 flex-1">
                  <label
                    htmlFor="file"
                    className="w-full flex gap-4 items-center"
                  >
                    <div className="w-full mb-2 rounded-lg overflow-hidden flex gap-4 item-center">
                      {fileName !== null ? (
                        <img
                          src={fileName}
                          className={`w-[150px] h-[150px] rounded-lg object-cover`}
                        />
                      ) : (
                        <IoImagesSharp size={50} />
                      )}
                      <input
                        type="file"
                        id="file"
                        onChange={imageChange}
                        accept="image/png, image/jpg, image/jpeg"
                        className="hidden"
                      />
                      รูปโปรไฟล์{" "}
                    </div>
                  </label>
                </div>
                {/* //firstname,lastname,phone,email,password,pltype */}
                {/*                 <div className="mb-2 flex-1">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={firstnameEdit}
                onChange={(e) => setFirstnameEdit(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div> */}
              </div>
              {/* ----  */}

              {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
              <div className="flex justify-end">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="w-full text-center bg-sky-500 text-white py-2.5 rounded-sm"
                >
                  {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
                </button>
              </div>
            </form>
            {/* -----------  */}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default EditProfile;
