import React, { useEffect, useState, useContext } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";

import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  shopUrl,
  imgShopUrl,
} from "../constants/BaseUrl";
import axios from "axios";
// import MainOccupationCard from "./../components/MainOccupationCard";
import {
  MainOccupationCard,
  NearProviceCard,
  TopProviceCard,
  Header,
  CompanyCard,
  HeaderBack,
} from "../components";
import { UserLocationContext } from "../context/UserLocationContext";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { HiOutlinePlus } from "react-icons/hi";
import { IoImagesSharp } from "react-icons/io5";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { GrDocumentPdf } from "react-icons/gr";
import { FaRegStar, FaRegIdCard } from "react-icons/fa";
import { GrDocumentUpdate } from "react-icons/gr";
import { FaRegCommentDots, FaMapMarkerAlt } from "react-icons/fa";
const CompanyProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [geocoding, setGeocoding] = useState(null);
  const longdo_key = "6b75448d3c0808af052906060b770d1c";
  // const [currentLocation, setCurrentLocation] = useState(null);
  const { location, setLocation } = useContext(UserLocationContext);
  const [userId, setUserId] = useState("");
  const [companyInfoData, setCompanyInfoData] = useState({});
  const [logoData, setLogoData] = useState({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleChange = (checked) => {
    console.log(checked);
    setIsChecked(checked);
  };

  function readCompany(id) {
    axios.get(`${shopUrl}readowncompany.php/${id}`).then(function (response) {
      setCompanyInfoData(response.data);
    });
  }

  useEffect(() => {
    var user = localStorage.getItem("pvid");
    var open = localStorage.getItem("isopen");
    var comOpen = companyInfoData?.open == "1" ? true : false;
    setUserId(user);
    setIsOpen(comOpen);
    readCompany(id);
    // setIsEnabled(comOpen);
    setIsChecked(comOpen);
    console.log("Company Open: ", comOpen);
    console.log("Check Open: ", isChecked);
    console.log("Is Open: ", isOpen);
    console.log("Open: ", open);
    /*     if (user === null) {
      navigate("/signin");
    } */
  }, []);

  // console.log("My Com: ", companyInfoData);
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-4">
        <div className="mt-[5rem]">
          {/* -------- #504f4f */}
          <div
            className={`w-[${windowSize[0]}px] h-[${
              windowSize * 0.5
            }px] rounded-sm border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            {/* -----------  */}
            <div className="flex flex-row gap-4 py-2">
              <div className="w-[80px] h-[80px] rounded-full overflow-hidden mb-1">
                {companyInfoData?.companyPhoto !== null ? (
                  <>
                    <img
                      src={`${imgShopUrl}${companyInfoData.companyPhoto}`}
                      alt={companyInfoData.companyPhoto}
                      className="w-[80px] h-[80px] rounded-sm object-cover"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={require("../assets/images/person_color.png")}
                      alt={""}
                      className="w-[80px] h-[80px] rounded-sm object-cover"
                    />
                  </>
                )}
              </div>
              <div className="flex-1 w-full">
                <p className="text-sm font-medium">
                  {companyInfoData?.mainOcc
                    ? companyInfoData?.minorOcc
                    : "ไม่ได้ระบุอาชีพ"}
                </p>
                <p className="font-medium">{companyInfoData?.companyName}</p>
                <p className="text-xs">{companyInfoData?.companyDetail}</p>
              </div>
            </div>
            <div className="flex flex-row gap-2 justify-center mb-2  p-2 bg-slate-50 border-t border-b">
              {companyInfoData?.address &&
              companyInfoData?.road &&
              companyInfoData?.tambon &&
              companyInfoData?.amphur &&
              companyInfoData?.province &&
              companyInfoData?.zipCode ? (
                <>
                  {" "}
                  <p className="text-sm font-medium">
                    {companyInfoData?.address} {companyInfoData?.road}{" "}
                    {companyInfoData?.tambon} {companyInfoData?.amphur}{" "}
                    {companyInfoData?.province} {companyInfoData?.zipCode}
                  </p>
                  <Link
                    to={`/providerlocation/${companyInfoData?.longitude}/${companyInfoData?.latitude}`}
                    className="text-sm font-medium flex justify-center flex-col items-center"
                  >
                    <FaMapMarkerAlt fontSize={22} color={"red"} />
                    Map
                  </Link>
                </>
              ) : (
                <p className="text-sm font-medium">
                  ไม่ได้ระบุพิกัดที่ตั้งร้านค้า
                </p>
              )}
            </div>
            <div className="flex flex-row gap-2 justify-end mb-2">
              <Switch onChange={handleChange} checked={isChecked} />
              {/* {companyInfoData?.isopen} */}
              {/*         <Link
          to={`/companyprofile/${item?.id}`}
          className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
        >
          จัดการร้านค้า
        </Link>
        <Link
          to={"/editprofile"}
          className="w-[100px] text-center bg-red-500 text-white py-1 rounded-sm"
        >
          ลบร้านค้า
        </Link> */}
              {/*               <button
            onClick={() => {}}
            className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
          >
            แก้ไขโปรไฟล์
          </button> */}
            </div>
            {/* -----------  */}
          </div>
          {/* --------  */}
          <div className="mt-[2rem]">
            <div className="grid grid-cols-2 gap-4 px-0 pb-[2rem]">
              {/* -----  */}
              <div className="cursor-pointer border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                {/*                 <button
                  onClick={() => {}}
                  className="flex justify-center flex-col items-center w-full"
                >
                  <GrDocumentPdf size={30} />
                  <strong className="text-sm font-medium mt-1">
                    ขอใบเสนอราคา shoplocation
                  </strong>
                </button> */}
                <Link
                  to={`/shoplocation/${id}`}
                  className="flex justify-center flex-col items-center w-full"
                >
                  {/* <FaRegStar size={30} /> */}
                  <img
                    src={require("../assets/images/map01.png")}
                    alt={""}
                    className="w-[100px] h-[100px] rounded-full object-cover"
                  />
                  <strong className="text-sm font-medium mt-1">
                    พิกัดที่ตั้งร้านค้า
                  </strong>
                </Link>
              </div>
              <div className="cursor-pointer border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                {/*                 <button
                  onClick={() => {}}
                  className="flex justify-center flex-col items-center w-full"
                >
                  <GrDocumentUpdate size={30} />
                  <strong className="text-sm font-medium mt-1">
                    ส่งเอกสารจัดจ้าง searchoccupation
                  </strong>
                </button> */}
                <Link
                  to={`/searchoccupation/${id}`}
                  className="flex justify-center flex-col items-center w-full"
                >
                  {/* <FaRegStar size={30} /> */}
                  <img
                    src={require("../assets/images/occupation.png")}
                    alt={""}
                    className="w-[100px] h-[100px] rounded-full object-cover"
                  />
                  <strong className="text-sm font-medium mt-1">
                    อาชีพบริการ
                  </strong>
                </Link>
              </div>
              <div className="cursor-pointer border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                {/*                 <button
                  onClick={() => {}}
                  className="flex justify-center flex-col items-center w-full"
                >
                  <FaRegIdCard size={30} />
                  <strong className="text-sm font-medium mt-1">
                    แนบบัตรประชาชน shopgallery
                  </strong>
                </button> */}
                <Link
                  to={`/shopgallery/${id}`}
                  className="flex justify-center flex-col items-center w-full"
                >
                  {/* <FaRegStar size={30} /> */}
                  <img
                    src={require("../assets/images/gallery01.png")}
                    alt={""}
                    className="w-[100px] h-[100px] rounded-full object-cover"
                  />
                  <strong className="text-sm font-medium mt-1">
                    รูปผลงาน(Gallery)
                  </strong>
                </Link>
              </div>
              <div className="cursor-pointer border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                <Link
                  to={`/sociallink/${id}`}
                  className="flex justify-center flex-col items-center w-full"
                >
                  {/* <FaRegStar size={30} /> */}
                  <img
                    src={require("../assets/images/social03.png")}
                    alt={""}
                    className="w-[100px] h-[100px] rounded-full object-cover"
                  />
                  <strong className="text-sm font-medium mt-1">
                    ลิงค์ผลงาน
                  </strong>
                </Link>
              </div>
              <div className="cursor-pointer border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                <Link
                  to={`/editshop/${id}`}
                  className="flex justify-center flex-col items-center w-full"
                >
                  {/* <FaRegStar size={30} /> */}
                  <img
                    src={require("../assets/images/shop01.png")}
                    alt={""}
                    className="w-[100px] h-[100px] rounded-full object-cover"
                  />
                  <strong className="text-sm font-medium mt-1">
                    แก้ไขร้านค้า
                  </strong>
                </Link>
              </div>
              {/* -----  */}
            </div>
          </div>
          {/* --------  */}
        </div>
      </div>
      <div className="mt-[100px] text-white">123</div>
    </>
  );
};

export default CompanyProfile;
