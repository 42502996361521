import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
  shopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, SocialCard, SocialMediaCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import { IoImagesSharp } from "react-icons/io5";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";
import {
  FaGlobe,
  FaYoutube,
  FaFacebookSquare,
  FaTiktok,
  FaInstagramSquare,
  FaTwitter,
} from "react-icons/fa";
const data = [
  { key: "Facebook", value: "Facebook", icon: <FaFacebookSquare size={30} /> },
  { key: "Youtube", value: "Youtube", icon: <FaYoutube size={30} /> },
  { key: "Twitter", value: "Twitter", icon: <FaTwitter size={30} /> },
  {
    key: "Instagram",
    value: "Instagram",
    icon: <FaInstagramSquare size={30} />,
  },
  { key: "TikTok", value: "TikTok", icon: <FaTiktok size={30} /> },
  { key: "Website", value: "Website", icon: <FaGlobe size={30} /> },
];

const SocialLink = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [selected, setSelected] = useState(null);
  const [socialLink, setSocialLink] = useState("");
  const [socialData, setSocialData] = useState([]);
  const [userId, setUserId] = useState("");

  const [open, setOpen] = useState(false);

  const onOpenModal = (item) => {
    setSelected(item);
    setOpen(true);
    console.log("ITEM: ", item);
  };
  const onCloseModal = () => setOpen(false);

  const readSocial = (id) => {
    axios.get(`${shopUrl}readsocial.php/${id}`).then(function (response) {
      console.log(response.data);
      setSocialData(response.data);
    });
  };

  useEffect(() => {
    var user = localStorage.getItem("pvid");
    var open = localStorage.getItem("isopen");
    setUserId(user);
    readSocial(id);
  }, []);

  const onSend = async () => {
    try {
      if (socialLink === "") {
        Swal.fire({
          title: "แจ้งเตือน",
          text: "กรุณาป้อนลืงค์",
          icon: "error",
        });
        return;
      }

      axios
        .post(`${shopUrl}addsocial.php`, {
          memberId: userId,
          companyId: id,
          name: selected?.value,
          icon: selected?.value,
          url: socialLink,
        })
        .then(function (response) {
          console.log(response.data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          readSocial(id);

          setSocialLink(null);
          onCloseModal();
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem] mx-2">
          <div className="flex justify-between py-2 border border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
            {data.map((item, idx) => {
              //   setSelected(item);
              return (
                <button
                  key={idx}
                  onClick={() => onOpenModal(item)}
                  className="w-[42px] h-[42px] bg-sky-500 flex flex-col justify-center items-center rounded-full"
                >
                  {/* <item.icon name={item.icon} size={24} /> */}
                  {/* {JSON.stringify(item)} */}
                  {/* <item.icon size={20} /> */}
                  <div className="text-white">{item.icon}</div>
                  {/* <div>{item.value}</div> */}
                </button>
              );
            })}
          </div>
          <div className="mt-[4rem]">
            {socialData.length > 0 ? (
              <>
                <div className="flex flex-row gap-4 justify-between mx-1 mt-2 py-2 border border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-md overflow-hidden">
                  {socialData.map((item, idx) => (
                    <SocialMediaCard item={item} key={`social_${idx}`} />
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
        {/* -----------  */}
        <div>
          <Modal open={open} onClose={onCloseModal} center>
            <h2 className="text-lg mb-4 text-[#504f4f] font-medium flex justify-center items-center">
              ข้อมูลโซเชียลมีเดีย
            </h2>
            {/* <form onSubmit={handleOnSubmit}> */}
            <div className="mb-2 flex flex-col gap-2 text-[#504f4f] md:flex-row">
              <div className="mb-2 flex-1">
                <label>
                  เพิ่มลิงค์ {selected?.value ? selected?.value : null}
                </label>
                <input
                  type="url"
                  name="socialLink"
                  value={socialLink}
                  onChange={(e) => setSocialLink(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              {/* //firstname,lastname,phone,email,password,pltype */}
            </div>
            {/* ----  handleOnSubmit*/}

            {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
            <div className="flex justify-end">
              <button
                onClick={onSend}
                disabled={isLoading}
                type="button"
                className="w-full text-center bg-sky-500 text-white py-2.5 rounded-sm"
              >
                {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
            {/* </form> */}
          </Modal>
        </div>
        {/* -----------  */}
      </div>
    </>
  );
};

export default SocialLink;
