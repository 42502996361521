import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
  shopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, OccupationCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import { IoImagesSharp } from "react-icons/io5";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";

const SearchOccupation = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [searchItem, setSearchItem] = useState("");
  const [companyInfoData, setCompanyInfoData] = useState([]);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    searchCompany(searchTerm);
    setSearchItem(searchTerm);
  };

  function searchCompany(txtOcc) {
    setIsLoading(true);
    /*     const result = detail.replace(/[^0-9]/g, '');
    let result2 = result.slice(-5);
    console.log(result2); */
    axios
      // .get(`${shopUrl}searchoccupation.php/${txtOcc}`)
      .post(`${shopUrl}searchoccupation.php`, {
        searchText: txtOcc,
      })
      .then(function (response) {
        /*         console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data);
        console.log("---------Search"); */
        setCompanyInfoData(response?.data);
        // memberID
        // readLogo(response?.data.memberID);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    searchCompany(searchItem);
  }, []);

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[5rem]">
          <div>
            <input
              type="search"
              className="w-full mb-2 border py-2 px-3 text-black"
              value={searchItem}
              onChange={handleInputChange}
              placeholder={`ค้นหาอาชีพที่ต้องการ`}
            />
          </div>
          {/* -----------  */}
          <div>
            {companyInfoData.map((item, idx) => (
              <OccupationCard
                key={`minor_${idx}`}
                item={item}
                companyID={id}
                width={windowSize[0]}
              />
            ))}
          </div>
          {/* -----------  */}
        </div>
        <div className="mt-[200px]"></div>
      </div>
    </>
  );
};

export default SearchOccupation;
