import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
// import { useForm } from "react-hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  memberUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, HeadAuth } from "../components";
import axios from "axios";
import Swal from "sweetalert2";

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  // form validation rules
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required("required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "to short")
      .max(10, "to long"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    acceptTerms: Yup.bool().oneOf([true], "Accept Ts & Cs is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    // display form data on success
    /*     alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
      return false; */
    try {
      //checkemaildup.php
      axios
        .post(`${memberUrl}checkemaildup.php`, {
          // pid: userId,
          email: data.email,
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data.status === 1) {
            setIsEmail(true);
            setIsLoading(false);
            // setUser(response.data);
          } else {
            //-------------
            axios
              .post(`${memberUrl}signupprovider.php`, {
                phone: data.phone,
                email: data.email,
                password: data.password,
              })
              .then(function (response) {
                console.log(response.data);
                reset();
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setIsEmail(false);
                setIsLoading(false);
              });
            //-------------else
          }
        });
      /*       axios
          .post(`${pvuserUrl}add.php`, {
            phone: data.phone,
            email: data.email,
            password: data.password,
          })
          .then(function (response) {
            console.log(response.data);
            navigation.navigate('Policy', {userId: response.data.userId});
          }); */
    } catch (err) {
      console.log(err.message);
    }

    //------24.375rem
  }
  return (
    <>
      <div className="overflow-y-scroll">
        {/* -------  */}
        <div className="w-[24.375rem] mx-auto">
          <HeadAuth />
        </div>
        <div className="">
          {/* -------- max-w-[420px]  bg-purple-100 bg-[#1F212C]*/}

          <div className="relative min-h-screen backdrop-blur flex justify-center items-center bg-texture bg-cover py-28 sm:py-0">
            <div className="p-4 sm:p-8 flex-1 ">
              <div className="max-w-[420px] min-w-[320px] rounded-lg mx-auto">
                <div className="px-2 pt-8 pb-8 rounded-3xl shadow-xl">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-2">
                      <label className="mb-2.5 block font-medium">
                        โทรศัพท์
                      </label>
                      <input
                        name="phone"
                        type="tel"
                        {...register("phone")}
                        className={`w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] py-3 px-5 font-medium outline-none ${
                          errors.phone ? "border-red-500" : ""
                        }`}
                      />
                      <div className="my-3 text-normal text-red-500 text-xs italic">
                        {errors.phone?.message}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2.5 block font-medium">อีเมล์</label>
                      <input
                        name="email"
                        type="email"
                        {...register("email")}
                        className={`w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] py-3 px-5 font-medium outline-none ${
                          errors.email ? "border-red-500" : ""
                        }`}
                      />
                      <div className="my-3 text-normal text-red-500 text-xs italic">
                        {errors.email?.message}
                      </div>
                      {isEmail && (
                        <div className="my-3 text-normal text-red-500 text-xs italic">
                          Account already exists”, it means that the email
                          address you're trying to add, has been registered as
                          an account already
                        </div>
                      )}
                    </div>
                    <div className="mt-2">
                      <label className="mb-2.5 block font-medium">
                        รหัสผ่าน
                      </label>
                      <input
                        name="password"
                        type="password"
                        {...register("password")}
                        className={`w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] py-3 px-5 font-medium outline-none ${
                          errors.password ? "border-red-500" : ""
                        }`}
                      />
                      <div className="my-3 text-normal text-red-500 text-xs italic">
                        {errors.password?.message}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label className="mb-2.5 block font-medium">
                        ยืนยันรหัสผ่าน
                      </label>
                      <input
                        name="confirmPassword"
                        type="password"
                        {...register("confirmPassword")}
                        className={`w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)]  py-3 px-5 font-medium outline-none ${
                          errors.confirmPassword ? "border-red-500" : ""
                        }`}
                      />
                      <div className="my-3 text-normal text-red-500 text-xs italic">
                        {errors.confirmPassword?.message}
                      </div>
                    </div>
                    <div className="mt-2">
                      <input
                        name="acceptTerms"
                        type="checkbox"
                        {...register("acceptTerms")}
                        id="acceptTerms"
                        className={`border-[1.5px] border-stroke bg-[#1F212C] border-[#504f4f]  py-3 px-5 ${
                          errors.acceptTerms ? "border-red-500" : ""
                        }`}
                      />
                      <label
                        htmlFor="acceptTerms"
                        className="ml-2 inline-flex items-center font-medium"
                      >
                        ยอมรับเงื่อนไขการใช้งาน
                      </label>
                      <div className="my-3 text-normal text-red-500 text-xs italic">
                        {errors.acceptTerms?.message}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full mt-4 py-2 text-lg font-semibold text-white text-center rounded-md bg-blue-500 transition-all hover:bg-blue-600 focus:outline-none"
                    >
                      สมัครสมาชิก
                    </button>
                    <p className="text-center text-sm mt-4">
                      มีบัญชีแล้ว ?{" "}
                      <Link
                        to={"/signin"}
                        className="font-semibold hover:underline"
                      >
                        คลิกเข้าระบบ
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* --------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default Signup;
