import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { imgGalleryUrl } from "../constants/BaseUrl";

const GalleryCard = ({ item, width }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location; //w-[${width}px]
  return (
    <div className="cursor-pointer">
      <div className={`w-[150px] h-[150px]`}>
        <img
          src={`${imgGalleryUrl}${item.photo}`}
          alt={item.thName}
          className={`w-full h-full object-cover`}
        />
      </div>
    </div>
  );
};

export default GalleryCard;
