import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgUserUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import { GrDocumentPdf } from "react-icons/gr";
import { FaRegStar, FaRegIdCard } from "react-icons/fa";
import { GrDocumentUpdate } from "react-icons/gr";
import { BsShare } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { TiContacts } from "react-icons/ti";
import { GrGallery } from "react-icons/gr";
// import { HiMenuAlt1, HiChevronLeft } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { FaRegCommentDots, FaMapMarkerAlt } from "react-icons/fa";
const ContactDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const item = location.state;
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const { contactId, comId, uid, memId } = item;

  console.log("Hi My Item: ", item);
  const [isLoading, setIsLoading] = useState(false);
  const [userAccount, setUserAccount] = useState({});
  const [userId, setUserId] = useState(null);
  const readAccount = (id) => {
    axios.get(`${pvuserUrl}readuser.php/${id}/pvacc`).then(function (response) {
      console.log("User: ", response.data);
      setUserAccount(response.data);
    });
  };

  useEffect(() => {
    var id = localStorage.getItem("pvid");
    // var user = localStorage.getItem("ufullname");
    setUserId(id);
    readAccount(uid);
    // readPolicyData(2);
    // readAppTData(2);
  }, []);
  //usercontact
  const gotoUserContact = () => {
    /*     const itemData = {
          comId: item?.id,
          type: 1,
          title: "Contact",
        }; */
    navigate("/usercontact", { state: item });
  };

  console.log("userAccount: ", userAccount);

  //doccontacts
  const gotoDocContact = () => {
    const itemData = {
      comId: comId,
      uid: uid,
      memId: memId,
    };
    navigate("/doccontacts", { state: itemData });
  };

  //PricequoteContact
  const gotoPricequoteContact = () => {
    const itemData = {
      comId: item?.id,
      uid: userId,
      memId: item?.memberID,
    };
    navigate("/pricequotecontact", { state: itemData });
  };
  //sendidcard
  const gotoSendIdCard = () => {
    const itemData = {
      comId: item?.id,
      uid: userId,
      memId: item?.memberID,
    };
    navigate("/sendidcard", { state: itemData });
  };
  //ratings
  const gotoRating = () => {
    const itemData = {
      comId: item?.id,
      uid: userId,
      memId: item?.memberID,
    };
    navigate("/ratings", { state: itemData });
  };

  const gotoPricequote = () => {
    const itemData = {
      contactId: contactId,
      comId: comId,
      uid: uid,
      memId: memId,
    };
    navigate("/listPriceQuote", { state: itemData });
  };

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}
        {/*         <div className="mt-[4rem]">
          Contact:{contactId}, Company:{comId}, User:{uid}, MemberX:{memId}{" "}
        </div> */}
        <div className="mt-[5rem]">
          {/* -------  */}
          <div
            className={`w-[${windowSize[0]}px] h-[${
              windowSize[0] * 0.5
            }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            {/* ----------  */}
            <div className="flex flex-row gap-4 py-1">
              <div className="w-[80px] h-[80px] rounded-full overflow-hidden mb-1">
                {userAccount?.picture !== null ? (
                  <>
                    <img
                      src={`${imgUserUrl}${userAccount.picture}`}
                      alt={userAccount?.picture}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={require("../assets/images/person_color.png")}
                      alt={userAccount?.picture}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                )}
              </div>
              <div className="flex-1 w-full">
                <strong className="block text-sm font-medium">
                  {userAccount?.firstname !== ""
                    ? `${userAccount?.firstname} ${userAccount?.lastname}`
                    : "ไม่ระบุชื่อสกุล"}
                </strong>
                <strong className="text-sm font-medium">
                  {" "}
                  อีเมล์: {userAccount?.email}
                </strong>
                <span className="block text-xs">
                  โทรศัพท์: {userAccount?.phone}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <Link
                  to={`/userLocation/${uid}`}
                  className="text-sm font-medium cursor-pointer border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-full flex flex-col justify-center items-center w-[3rem] h-[3rem]"
                >
                  <FaMapMarkerAlt fontSize={22} />
                  Map
                </Link>
                <a
                  href={`tel:${userAccount?.phone}`}
                  className="text-sm font-medium cursor-pointer border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-full flex flex-col justify-center items-center w-[3rem] h-[3rem]"
                >
                  <FiPhoneCall fontSize={20} />
                  Call
                </a>
                <Link
                  to={`/userchat/${uid}/${comId}`}
                  className="text-sm font-medium cursor-pointer border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-full flex flex-col justify-center items-center w-[3rem] h-[3rem]"
                >
                  <IoChatboxEllipsesOutline fontSize={22} />
                  Chat
                </Link>
              </div>
            </div>

            {/* ---------- flex flex-row gap-4 justify-between mx-2 p-2 rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1 overflow-hidden */}
            {/* <div className="flex flex-row gap-4 justify-between overflow-hidden mb-1 overflow-hidden"> */}
            {/*               <Link
                to={`/userchat/${item?.memberID}/${item?.id}`}
                className="text-sm font-medium cursor-pointer border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-full flex flex-col justify-center items-center w-[3rem] h-[3rem]"
              >
                <IoChatboxEllipsesOutline fontSize={22} />
                Chat
              </Link> */}
            {/*           <button
            onClick={gotoUserChat}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <IoChatboxEllipsesOutline fontSize={22} />
            Chat
          </button> */}
            {/*               <a
                href={`tel:${userAccount?.phone}`}
                className="text-sm font-medium cursor-pointer border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-full flex flex-col justify-center items-center w-[3rem] h-[3rem]"
              >
                <FiPhoneCall fontSize={20} />
                Call
              </a> */}
            {/*               <button
                onClick={gotoUserContact}
                className="text-sm font-medium flex justify-center flex-col items-center"
              >
                <TiContacts fontSize={22} />
                Contact
              </button> */}
            {/*           <Link
            to={"/usercontact"}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <TiContacts fontSize={22} />
            Contact
          </Link> */}
            {/*               <button
                onClick={() => {}}
                className="text-sm font-medium flex justify-center flex-col items-center"
              >
                <BsShare fontSize={18} />
                share
              </button> */}
            {/*               <Link
                to={`/providergallery/${item?.id}`}
                className="text-sm font-medium flex justify-center flex-col items-center"
              >
                <GrGallery fontSize={22} />
                Gallery
              </Link> */}
            {/*               <Link
                to={"/providerlocation"}
                className="text-sm font-medium cursor-pointer border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-full flex flex-col justify-center items-center w-[3rem] h-[3rem]"
              >
                <FaMapMarkerAlt fontSize={22} />
                Map
              </Link> */}
            {/* </div> */}
            {/* ----------  */}
          </div>
          <div
            className={`w-[${windowSize[0]}px] h-[${
              windowSize[0] * 0.5
            }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            {/* ------------ */}
            <div className="grid grid-cols-2 gap-4 px-5 mt-[2rem] pb-[2rem]">
              {/* -----  */}
              <div className="cursor-pointer  border border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                <button
                  onClick={gotoPricequote}
                  className="flex justify-center flex-col items-center w-full"
                >
                  <GrDocumentPdf size={30} />
                  <strong className="text-sm font-medium mt-1">
                    ใบเสนอราคา
                  </strong>
                </button>
              </div>
              <div className="cursor-pointer  border border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                <button
                  onClick={gotoDocContact}
                  className="flex justify-center flex-col items-center w-full"
                >
                  <GrDocumentUpdate size={30} />
                  <strong className="text-sm font-medium mt-1">
                    ส่งเอกสารเพิ่มเติม
                  </strong>
                </button>
              </div>
              <div className="cursor-pointer  border border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                <button
                  onClick={gotoSendIdCard}
                  className="flex justify-center flex-col items-center w-full"
                >
                  <FaRegIdCard size={30} />
                  <strong className="text-sm font-medium mt-1">
                    แนบบัตรประชาชน
                  </strong>
                </button>
              </div>
              <div className="cursor-pointer border border-stroke border-[rgb(241 245 249)] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
                <Link
                  to={`/ratings/${item?.memberID}/${item?.id}`}
                  className="flex justify-center flex-col items-center w-full"
                >
                  <FaRegStar size={30} />
                  <strong className="text-sm font-medium mt-1">Ratings</strong>
                </Link>
              </div>
              {/* -----  */}
            </div>
            {/* ------------ */}
          </div>
          {/*           <div
            className={`w-[${windowSize[0]}px] h-[${
              windowSize[0] * 0.5
            }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          ></div> */}
          {/* -------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default ContactDetail;
