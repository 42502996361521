import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgChatUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
// import { IoSendSharp, IoImagesSharp } from "react-icons/io5";
import {
  IoSendSharp,
  IoImagesSharp,
  IoImageOutline,
  IoPaperPlaneOutline,
} from "react-icons/io5";

import InfiniteScroll from "react-infinite-scroll-component";
import "../App.css";

const UserChat = () => {
  //path: "/userchat/:memId/:comId",
  const { usrId, comId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [chats, setChats] = useState([]);
  const [text, setText] = useState("");
  const [searchItem, setSearchItem] = useState("");
  // const [fileName, setFileName] = useState(null);
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  const [memberId, setMemberId] = useState("");
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSectedFile] = useState(null);
  // const item = location.state;
  const inputRef = useRef(null);
  const bottomRef = useRef(null);
  // const [itemData, setItemData] = useState(item);

  // const { comId, uid, memId } = item;
  console.log("Hi Chat : ", usrId, "/".comId);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
    setFileName(URL.createObjectURL(e.target.files[0])); //.name
    // console.log("IMG: ", e.target.files[0]);
    // alert("UPFILES" + e.target.files[0].name);
    onUploadFile(e.target.files[0]);
  };

  const onUploadFile = async (myfile) => {
    /*             userId: usrId,
        comId: comId,
        memberId: memberId,
        text: text,
        // msgId: _id,
        chatDate: Date.now(),, */
    // alert("IMG: " + myfile);
    setIsLoading(true);
    try {
      let formData = new FormData();
      formData.append("userId", usrId);
      formData.append("comId", comId);
      formData.append("memberId", memberId);
      formData.append("fileToUpload", myfile);
      //upfileuserchat.php
      await axios
        .post(`${searchcompanyUrl}upfilechat.php`, formData)
        .then((response) => {
          console.log(response.data);
          readChats(usrId, memberId, comId);
          setFileName(null);
          setSectedFile(null);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //readuserchats.php
  const readChats = (uid, memId, comId) => {
    axios
      .get(
        `${searchcompanyUrl}readpvchats.php/${uid}/${memId}/${comId}/pvmycom`
      )
      .then(function (response) {
        // console.log("Chat data: ", response.data);
        // console.log("Chat data length: ", response.data.length);
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        if (response.data.length > 0) {
          setChats(response.data);
          setSkip(skip + 10);
        } else {
          setHasMore(false);
        }
      });
  };

  useEffect(() => {
    var mid = localStorage.getItem("pvid");
    var user = localStorage.getItem("pvfullname");
    /*     if (user === null) {
      navigate("/signin");
    } */
    // console.log("user: ", user);
    setAuth(user);
    setMemberId(mid);
    // readChats(uid, memId, comId);
    readChats(usrId, mid, comId);
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onSend = () => {
    // UserID: {userId}/MemberID: {item?.memberID}/Company ID: {item?.id}adduserchats
    axios
      .post(`${pvuserUrl}addpvchats.php`, {
        userId: usrId,
        comId: comId,
        memberId: memberId,
        text: text,
        // msgId: _id,
        chatDate: Date.now(),
      })
      .then(function (response) {
        console.log(response.data);
        // readChats(user?.uid, memId, comId);

        readChats(usrId, memberId, comId);
        // reference.scrollToEnd({animated: true});

        setText("");
        inputRef.current.focus();
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      });
  };
  // ----------
  const isSenderSame = ({ currentMessage, prevMessage }) => {
    return currentMessage.time === (prevMessage?.time || "");
  };

  const isNextSenderSame = ({ currentMessage, nextMessage }) => {
    return currentMessage.time === (nextMessage?.time || "");
  };

  const RenderItem = ({ item, index }) => {
    /*     console.log("Item: " + index, item);
    console.log(
      "Item memID: " + index,
      "Member ID:",
      item.memId,
      "-",
      item.sendToBy
    ); */
    // const iAmSender = item?.userId === item?.sendToBy ? true : false;
    const iAmSender = item?.memId === item?.sendToBy ? true : false;
    console.log("iAmSender=>", iAmSender);
    const previousTimeIsSame = isSenderSame({
      currentMessage: item,
      prevMessage: chats[index - 1],
    });

    const nextTimeIsSame = isNextSenderSame({
      currentMessage: item,
      nextMessage: chats[index + 1],
    });
    // reference.scrollToEnd({ animated: true });

    return (
      <>
        {iAmSender ? (
          <>
            <div className="w-full flex justify-end">
              <div>
                {item.textMsg !== "" ? (
                  <>
                    <div className="p-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-left rounded-tl-xl rounded-tr-xl rounded-bl-xl">
                      <strong className="">{item?.textMsg}</strong>
                    </div>
                  </>
                ) : null}
                {item?.file !== null ? (
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      // paddingRight: 35,
                      // marginVertical: SIZES.padding2,
                      // width: SIZES.width,
                    }}
                    // className="bg-red-500"
                  >
                    <img
                      src={`${imgChatUrl}thumbnail/${item?.file}`}
                      className="w-[150px] h-[150px] rounded-md object-cover"
                      // resizeMode="cover"
                    />
                  </div>
                ) : null}
                {previousTimeIsSame && (
                  <>
                    <div
                      style={{
                        textAlign: iAmSender ? "right" : "left",
                        backgroundColor: "blue",
                      }}
                    >
                      {item?.chatDate}
                    </div>
                  </>
                )}
                {!previousTimeIsSame && !nextTimeIsSame && (
                  <div
                    style={{
                      textAlign: !iAmSender ? "right" : "left",
                      // backgroundColor: "blue",
                    }}
                  >
                    {item?.chatDate}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex justify-start">
              <div>
                {item.textMsg !== "" ? (
                  <div className="p-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-left rounded-tl-xl rounded-tr-xl rounded-br-xl">
                    <strong className="">{item?.textMsg}</strong>
                  </div>
                ) : null}
                {item?.file !== null ? (
                  <div
                    style={{
                      alignSelf: "self-start",
                    }}
                  >
                    {/*                     <img
                      source={{ uri: `${imgChatUrl}thumbnail/${item?.file}` }}
                      className="w-[100px] h-[150px]  object-cover"
                    /> */}
                    <img
                      src={`${imgChatUrl}thumbnail/${item?.file}`}
                      className="w-[150px] h-[150px] rounded-md object-cover"
                      // resizeMode="cover"
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    alignItems: "flex-end",
                    // backgroundColor: "pink",
                  }}
                >
                  {previousTimeIsSame && (
                    <>
                      <div>{item?.chatDate}</div>
                    </>
                  )}
                  {!previousTimeIsSame && !nextTimeIsSame && (
                    <div
                      style={{
                        textAlign: iAmSender ? "right" : "left",
                      }}
                    >
                      {item?.chatDate}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };
  // --------

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    /*     searchCompany(searchTerm);
    setSearchItem(searchTerm); overflow-y-scroll*/
  };
  //   console.log("HHH: ", windowSize[1] - 200);
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className={`mt-[6rem]`}>
          {/*           <h2 className="">
            user:{usrId},mem:{memberId}, com:{comId}
          </h2> */}
          <div className="mx-2 mb-[10rem]">
            {/*             <InfiniteScroll
          dataLength={chats.length}
          next={readChats(userId, memId, comId)}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        > */}
            {chats.map((item, idx) => {
              return <RenderItem key={idx} item={item} index={idx} />;
            })}
            {/* </InfiniteScroll> */}
          </div>
          {/*           <div className={`absolute w-full left-0 bottom-[200px]`}>
        <div className="flex items-center">
          <input
            ref={inputRef}
            type="text"
            className="w-full h-[43px] bg-sky-200 text-black p-2 mr-2"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={`Enter...`}
          />
          <button
            onClick={onSend}
            type="button"
            className="w-[45px] h-[43px] flex justify-center items-center bg-green-50 mr-2"
          >
            <IoSendSharp fontSize={24} color="blue" />
          </button>
        </div>
      </div> */}
        </div>

        {/* -------  */}
        <div className={`fixed flex w-full left-0 bottom-[6rem]`}>
          <div className="">
            <label htmlFor="file" className="flex items-center text-[#504f4f]">
              <div className="flex flex-col items-start">
                {fileName !== null ? (
                  <>
                    <img
                      src={fileName}
                      className={`w-[10px] h-[10
                          px] object-cover`}
                    />
                  </>
                ) : (
                  <IoImageOutline size={45} color="#39A7FF" />
                )}
                <input
                  type="file"
                  id="file"
                  onChange={handleOnChange}
                  className="hidden"
                />
              </div>
            </label>
          </div>
          <div className="flex w-full items-center">
            <input
              ref={inputRef}
              type="text"
              className="w-full rounded-lg text-black border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] p-2"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder={``}
            />
            <button
              onClick={onSend}
              type="button"
              className="p-1.5 mx-1 rounded-full bg-[#E0F4FF] flex justify-center items-center"
            >
              <IoSendSharp fontSize={30} color="39A7FF" />
            </button>
          </div>
        </div>
        {/*         <div
          className={`fixed flex mx-1 w-full left-0 bottom-[6rem] bg-zinc-100`}
        >
          <div className="">
            <label
              htmlFor="file"
              className="flex gap-4 items-center text-[#504f4f]"
            >
              <div className="flex flex-col items-start">
                {fileName !== null ? (
                  <>
                    <img
                      src={fileName}
                      className={`w-[10px] h-[10
                          px] object-cover`}
                    />
                  </>
                ) : (
                  <IoImagesSharp size={50} color="#504f4f" />
                )}
                <input
                  type="file"
                  id="file"
                  onChange={handleOnChange}
                  className="hidden"
                />
              </div>
            </label>
          </div>
          <div className="flex flex-1 items-center px-2">
            <input
              ref={inputRef}
              type="text"
              className="w-full h-[43px] bg-sky-200 text-black border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] p-2 mr-2"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <button
              onClick={onSend}
              type="button"
              className="w-[45px] h-[43px] flex justify-center items-center bg-sky-200"
            >
              <IoSendSharp fontSize={24} color="blue" />
            </button>
          </div>
        </div> */}
        {/* -------  */}
      </div>
      <div className="mt-[200px] text-white" ref={bottomRef}>
        123
      </div>
      {/* <div ref={bottomRef} /> */}
    </>
  );
};

export default UserChat;
