import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";
import SignatureCanvas from "react-signature-canvas";
//signature
const AddPriceQuote = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [subject, setSubject] = useState("");
  const [yourDiscription, setYourDiscription] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const item = location.state;
  const { contactId, comId, uid, memId } = item;
  console.log("Hi Item: ", item);
  const [customerName, setCustomerName] = useState("");
  const [signatureData, setSignatureData] = useState("");
  const [workName, setWorkName] = useState("");
  const [workDetail, setWorkDetail] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceQty, setServiceQty] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [pvName, setPvName] = useState("");
  const [pvAddress, setPvAddress] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [services, setServices] = useState([]);
  const [docContactData, setDocContactData] = useState([]);
  const [selectedFile, setSectedFile] = useState(null);

  const [sign, setSign] = useState();
  const [url, setUrl] = useState();

  const sigCanvas = useRef(null);
  //https://www.dhiwise.com/post/how-to-implement-react-signature-canvas-in-your-applications
  //https://ozkaptanhakan.medium.com/how-to-add-react-signature-canvas-on-react-hook-form-f1e93929959e
  const handleClear = () => {
    // sign.clear();
    sigCanvas.current.clear();
    setUrl("");
  };
  const handleGenerate = () => {
    setUrl(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    // setSectedFile(sign.getTrimmedCanvas().FormData(sign.toData()));
    const data = sigCanvas.current.toData();
    const dataURL = sigCanvas.current.toDataURL();
    // Save the data array for later use
    console.log("Saved signature data:", data);
    console.log("Saved signature fromData:", dataURL);
    // console.log("URL: ", url);
    setSectedFile(data);
    // console.log("URLFROM DATA: ", sigCanvas.current.fromData(data));
  };

  /*   const [pointsArray, setPointsArray] = React.useState(null);
  const save = () => {
    setPointsArray(sigCanvas.current.toData());
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };
  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(() => {
      if (pointsArray) {
        sigCanvas.current.fromData(pointsArray);
      }
    });
  }; */

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const addServices = () => {
    // console.log('Services');
    if (serviceName == "" && (serviceQty == "") & (servicePrice == "")) {
      //   Alert.alert("แจ้งเตือน", "กรุณาป้อนข้อมูลให้ครบ!!!");
      Swal.fire({
        title: "แจ้งเตือน",
        text: "กรุณาป้อนข้อมูลให้ครบ!!!",
        icon: "error",
      });
      return;
    } else {
      //{id: 1, topic: 'topic 1', qty: '0', price: '0'},
      /*       console.log(serviceName);
      console.log(serviceQty);
      console.log(servicePrice); */
      const newService = {
        id: Math.random(),
        topic: serviceName,
        qty: serviceQty,
        price: servicePrice,
      };
      setTotalPrice((prev) => Number(prev) + serviceQty * servicePrice);
      setServices([...services, newService]);
      setServiceName("");
      setServiceQty("");
      setServicePrice("");
    }
  };
  const clearAlldata = () => {
    /*     Alert.alert("ยืนยัน", "ต้องการลบข้อมูลทั้งหมด?", [
      {
        text: "Yes",
        onPress: () => {
          setServices([]);
          setTotalPrice("0");
        },
      },
      {
        text: "No",
      },
    ]); */
    Swal.fire({
      title: "ยืนยัน",
      text: "ต้องการลบข้อมูลทั้งหมด?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setServices([]);
        setTotalPrice("0");
      }
    });
  };

  const deleteService = (serviceId, qty, price) => {
    /*     Alert.alert("ยืนยัน", "ต้องการลบข้อมูลนี้?", [
      {
        text: "Yes",
        onPress: () => {
          const newItem = services.filter((item) => item.id != serviceId);
          setTotalPrice((prev) => Number(prev) - qty * price);
          setServices(newItem);
        },
      },
      {
        text: "No",
      },
    ]); */
    //----------
    Swal.fire({
      title: "ยืนยัน",
      text: "ต้องการลบข้อมูลนี้?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const newItem = services.filter((item) => item.id != serviceId);
        setTotalPrice((prev) => Number(prev) - qty * price);
        setServices(newItem);
      }
    });

    //-------
  };

  const onSend = async () => {
    handleGenerate();
    try {
      if (pvName === null || pvAddress === null) {
        // Alert.alert('แจ้งเตือน', 'กรุณาป้อนข้อมูลให้ครบ!!!');
        Swal.fire({
          title: "แจ้งเตือน",
          text: "กรุณาป้อนข้อมูลให้ครบ!!!",
          icon: "error",
        });
        return;
      }

      const data = {
        contactId: contactId,
        comId: comId,
        userId: uid,
        memId: memId,
        customername: customerName,
        jobname: workName,
        jobdetail: workDetail,
        providername: pvName,
        provideraddress: pvAddress,
        services: services,
        total: totalPrice,
        signature: url,
      };

      /*       const formData = new FormData();
      formData.append("contactId", contactId);
      formData.append("userId", uid);
      formData.append("memId", memId);
      formData.append("comId", comId);
      formData.append("customername", customerName);
      formData.append("jobname", workName);
      formData.append("jobdetail", workDetail);
      formData.append("providername", pvName);
      formData.append("provideraddress", pvAddress);
      formData.append("services", services);
      formData.append("total", totalPrice);
      formData.append("signature", selectedFile); */
      //   { contactId, comId, uid, memId }
      //addpricequotewithimg.php
      //addpricequote.php
      await axios
        .post(`${searchcompanyUrl}addpricequote.php`, data)
        .then((response) => {
          /*           console.log(response.data);
          console.log('Save Success'); */
          setCustomerName("");
          setPvName("");
          setPvAddress("");
          setTotalPrice("");
          setWorkName("");
          setWorkDetail("");
          setServices([]);
          handleClear();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
      //--------
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[5rem]">
          {/*           <div>
            {" "}
            Contact:{contactId}, Company:{comId}, User:{uid}, Member:{memId}
          </div> */}
          <div className="">
            <div>
              <strong>ใบเสนอราคา</strong>
            </div>
            {/* -----------  */}
            {/* {JSON.stringify(docContactData)} */}
            <div
              className={`w-[${windowSize[0]}px] h-[${
                windowSize[0] * 0.5
              }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
            >
              <div className="mb-2">
                <span>ลูกค้า:</span>
                <strong>{customerName}</strong>
              </div>
              <div className="mb-2">
                <span>ชื่องาน:</span>
                <strong>{workName}</strong>
              </div>
              <div className="mb-2">
                <span>รายละเอียดงาน:</span>
                <strong>{workDetail}</strong>
              </div>
              <div className="mb-2 text-red-500">
                <strong>***ต้องป้อนชื่อลูกค้าและชื่องาน***</strong>
              </div>
              <div className="mb-2 flex-1">
                <label>ชื่อลูกค้า:</label>
                <input
                  type="text"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-2 flex-1">
                <label>ชื่องาน:</label>
                <input
                  type="text"
                  value={workName}
                  onChange={(e) => setWorkName(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-2 flex-1">
                <label>รายละเอียดงาน:</label>
                <input
                  type="text"
                  value={workDetail}
                  onChange={(e) => setWorkDetail(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            {/* -----------  */}
            {customerName != "" && workName != "" ? (
              <div
                className={`w-[${windowSize[0]}px] h-[${
                  windowSize[0] * 0.5
                }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
              >
                {/* --- */}

                <>
                  <div className="flex justify-between font-semibold">
                    <div>รายการ</div>
                    <div>จำนวน</div>
                    <div>ราคา(บาท)</div>
                    <div>Action</div>
                  </div>
                  <div>
                    {services.map((item, index) => {
                      return (
                        <div key={index} className="flex justify-between">
                          <p>{item?.topic}</p>
                          <div>{item?.qty}</div>
                          <p>{item?.price}</p>
                          <div>
                            <button
                              onClick={() =>
                                deleteService(item?.id, item?.qty, item?.price)
                              }
                              className=""
                            >
                              <strong className="text-sm font-medium mt-1">
                                X
                              </strong>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-2">
                    {/* ---  */}
                    <div className="mb-2 flex-1">
                      <label>ชื่อบริการ:</label>
                      <input
                        type="text"
                        value={serviceName}
                        onChange={(e) => setServiceName(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-2 flex-1">
                      <label>จำนวน:</label>
                      <input
                        type="number"
                        value={serviceQty}
                        onChange={(e) => setServiceQty(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-2 flex-1">
                      <label>ราคา(บาท):</label>
                      <input
                        type="number"
                        value={servicePrice}
                        onChange={(e) => setServicePrice(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="flex justify-between">
                      <button
                        onClick={clearAlldata}
                        className="w-[100px] text-center bg-red-500 text-white py-1 rounded-sm"
                      >
                        <strong className="text-sm font-medium mt-1">
                          ลบทั้งหมด
                        </strong>
                      </button>
                      <button
                        onClick={addServices}
                        className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
                      >
                        <strong className="text-sm font-medium mt-1">
                          เพิ่มบริการ
                        </strong>
                      </button>
                    </div>
                    {/* ---  */}
                  </div>
                </>
              </div>
            ) : (
              <></>
            )}
            {/* -----------  */}
            {customerName != "" && workName != "" && services.length > 0 ? (
              <div
                className={`w-[${windowSize[0]}px] h-[${
                  windowSize[0] * 0.5
                }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
              >
                {/* ---------  */}

                <>
                  <div className="mb-2">
                    <span>ผู้ขาย:</span>
                    <strong>{pvName}</strong>
                  </div>
                  <div className="mb-2">
                    <span>ที่อยู่:</span>
                    <strong>{pvAddress}</strong>
                  </div>
                  <div>
                    <div className="mb-2 flex-1">
                      <label>ชื่อผู้ให้บริการ*:</label>
                      <input
                        type="text"
                        value={pvName}
                        onChange={(e) => setPvName(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                    <div className="mb-2 flex-1">
                      <label>ที่อยู่ผู้ให้บริการ:</label>
                      <input
                        type="text"
                        value={pvAddress}
                        onChange={(e) => setPvAddress(e.target.value)}
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-2 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>
                  </div>
                </>

                {/* ---------  */}
              </div>
            ) : (
              <></>
            )}
            {/* -----------  */}
            {/*             <div
              className={`w-[${windowSize[0]}px] h-[${
                windowSize[0] * 0.5
              }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
            ></div> */}
            {/* -----------  */}
            {customerName != "" && workName != "" && services.length > 0 ? (
              <>
                <div
                  className={`w-[${windowSize[0]}px] h-[${
                    windowSize[0] * 0.5
                  }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
                >
                  <SignatureCanvas
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    // ref={(data) => setSign(data)}
                    ref={sigCanvas}
                  />
                  <div className="flex justify-between">
                    <button
                      className="w-[100px] text-center bg-red-500 text-white py-1 rounded-sm"
                      onClick={handleClear}
                    >
                      <strong className="text-sm font-medium mt-1">
                        เคลียร์
                      </strong>
                    </button>
                    <button
                      className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
                      onClick={handleGenerate}
                    >
                      <strong className="text-sm font-medium mt-1">
                        บันทึก
                      </strong>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {/* -----------  */}
            {customerName != "" && workName != "" && services.length > 0 ? (
              <div
                className={`w-[${windowSize[0]}px] h-[${
                  windowSize[0] * 0.5
                }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
              >
                {/* ---------  */}
                <div className="mb-2 flex justify-between">
                  <strong>ยอดรวมสุทธิ(บาท):</strong>
                  <strong>{totalPrice}</strong>
                </div>
                <div className="flex justify-end mb-2">
                  <button
                    onClick={onSend}
                    className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
                  >
                    <strong className="text-sm font-medium mt-1">บันทึก</strong>
                  </button>
                </div>

                {/* ---------  */}
              </div>
            ) : (
              <></>
            )}

            {/* -----------  */}
          </div>
          {/* -----------  */}

          {/* -----------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default AddPriceQuote;
