import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgMinorUrl,
  shopUrl,
} from "../constants/BaseUrl";
import axios from "axios";
import Swal from "sweetalert2";
const OccupationCard = ({ item, width, companyID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    var id = localStorage.getItem("pvid");

    setUserId(id);
  }, []);
  const onSaveCompany = () => {
    try {
      setIsLoading(true);
      //minorOccId=?, mainOcc=?,minorOcc=?
      axios
        .post(`${shopUrl}updateoccupation.php`, {
          id: item?.mid,
          memId: userId,
          companyId: companyID,
          moccId: item?.id,
          occupationName: item?.thName,
        })
        .then(function (response) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          /*           showMessage({
                message: 'แจ้งเตือน',
                description: 'บันทึกช้อมูลเรียบร้อยแล้ว',
                type: 'success',
              }); */
          // Alert.alert('แจ้งเตือน', 'บันทึกช้อมูลเรียบร้อยแล้ว');
        });
      // + JSON.stringify(selected)
      /*       setModalVisible(false);
          setSelected(null); */
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className={`w-[${width[0]}px] h-[${
        width * 0.5
      }px] rounded-sm border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
    >
      {/* -----------  */}
      <div className="flex flex-row gap-4 py-2">
        <div className="w-[80px] h-[80px] rounded-sm overflow-hidden mb-1">
          {item?.photo !== null ? (
            <>
              <img
                src={`${imgMinorUrl}${item.photo}`}
                alt={item.photo}
                className="w-[80px] h-[80px] rounded-sm object-cover"
              />
            </>
          ) : (
            <>
              <img
                src={require("../assets/images/person_color.png")}
                alt={""}
                className="w-[80px] h-[80px] rounded-sm object-cover"
              />
            </>
          )}
        </div>
        <div className="flex-1 w-full">
          <p className="font-medium">{item?.thName}</p>
          <p className="text-xs">{item?.enName}</p>
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-end mb-2">
        <button
          onClick={onSaveCompany}
          className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
        >
          เลือกอาชีพนี้
        </button>
      </div>
      {/* -----------  */}
    </div>
  );
};

export default OccupationCard;
