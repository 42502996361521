import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";

import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  shopUrl,
  loginUrl,
} from "../constants/BaseUrl";
import axios from "axios";
// import MainOccupationCard from "./../components/MainOccupationCard";
import {
  MainOccupationCard,
  NearProviceCard,
  TopProviceCard,
  Header,
  CompanyCard,
  NotificationCard,
} from "../components";
import { UserLocationContext } from "../context/UserLocationContext";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { HiOutlinePlus } from "react-icons/hi";
import { IoImagesSharp } from "react-icons/io5";
import Swal from "sweetalert2";
const HomeServices = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [geocoding, setGeocoding] = useState(null);
  const longdo_key = "9820e2a8e06a7cf53d8521d1517578dd";
  // const [currentLocation, setCurrentLocation] = useState(null);
  const { location, setLocation } = useContext(UserLocationContext);
  const [userId, setUserId] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyDiscription, setCompanyDiscription] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [phone, setPhone] = useState("");

  const [idCards, setIdCards] = useState([]);
  const [userAccount, setUserAccount] = useState({});

  const [fileName, setFileName] = useState(null);

  const [selectedFile, setSectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const handleOnChange = (e) => {
    // console.log("----------");
    // console.log(e.target.files[0]);
    // console.log("----------");
    // setSectedFile(e.target.files[0]);
    // setFileName(e.target.files[0]);
    setSectedFile(e.target.files[0]);
    setFileName(URL.createObjectURL(e.target.files[0])); //.name
    // console.log("IMG: ", e.target.files[0]);
  };

  function readCompany(id) {
    axios.get(`${shopUrl}readmycompany.php/${id}`).then(function (response) {
      setCompanyInfoData(response.data);
    });
  }

  const readCard = (id) => {
    axios
      .get(`${loginUrl}readpvcard.php/${id}/pvacc`)
      .then(function (response) {
        // console.log("Card ID: ", response.data);
        /*         setCardData(response.data);
        setCardEditId(response.data?.cardId);
        setCardNo(response.data?.id); */
        setIdCards(response.data);
      });
  };

  const readAccount = (id) => {
    axios
      .get(`${shopUrl}readuserlogin.php/${id}/pvacc`)
      .then(function (response) {
        // console.log("PV Member: ", response.data);
        setUserAccount(response.data);
        // readApi(response.data.country);
      });
  };

  useEffect(() => {
    var user = localStorage.getItem("pvid");
    setUserId(user);
    readCompany(user);
    readCard(user);
    readAccount(user);
    /*     if (user === null) {
      navigate("/signin");
    } */
  }, []);
  const handleOnSubmit = () => {
    if (companyName === "" || phone === "") {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "กรุณาป้อนชื่อร้านและโทรศัพท์",
        icon: "error",
      });
      return;
    }

    setIsLoading(true);
    let formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("memid", userId);
    formData.append("companyName", companyName);
    formData.append("companyDetail", companyDiscription);
    formData.append("companyContact", companyAddress);
    formData.append("phone", phone);

    axios.post(`${shopUrl}addshop.php`, formData).then(function (response) {
      // console.log(response.data);
      // readDocContact(comId, uid, memId);
      // setTitle("");
      // setSelectedImage(null);
      // readIDCard(comId, uid, memId, 0);
      readCompany(userId);
      setCompanyName("");
      setCompanyAddress("");
      setCompanyDiscription("");
      setPhone("");
      setFileName(null);
      setSectedFile(null);
      onCloseModal();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsLoading(false);
    });
  };

  // console.log("My Company: ", companyInfoData);
  return (
    <>
      <Header />
      <div className="overflow-y-scroll mx-auto">
        <div className="mt-[5rem]">
          <div className="flex justify-end  px-4 mb-4">
            <button
              onClick={onOpenModal}
              className="cursor-pointer w-[100px] py-1 bg-sky-500 text-white rounded-full flex justify-center items-center "
            >
              {/* <HiOutlinePlus size={30} /> w-[2.75rem] h-[2.75rem]*/}
              เพิ่มร้านค้า
            </button>
          </div>
          <div className="mb-2">
            {/* -------  */}
            {userAccount?.firstname === "" && userAccount?.lastname === "" ? (
              <>
                <NotificationCard
                  title={"แจ้งเตือน"}
                  text={"กรุณาป้อนข้อมูลชื่อ-สกุลที่เมนูตั้งค่า"}
                  url={"userprofile"}
                />
              </>
            ) : null}

            {Object.keys(idCards).length === 0 ? (
              <>
                <NotificationCard
                  title={"แจ้งเตือน"}
                  text={"กรุณาป้อนข้อมูลบัตรประชาชนที่เมนูตั้งค่า"}
                  url={"addIDCard"}
                />
              </>
            ) : null}

            {/* -------  */}
          </div>
          {/* -------  */}
          {Object.keys(companyInfoData).length !== 0 ? (
            <>
              <div className="grid grid-cols-1 gap-4 mx-4 pb-[6rem]">
                {companyInfoData.map((item, idx) => {
                  return (
                    <CompanyCard key={idx} item={item} width={windowSize[0]} />
                  );
                })}
              </div>
            </>
          ) : null}
          {/* -------  */}
          {/* -------  */}
          <div>
            <Modal open={open} onClose={onCloseModal} center>
              <h2 className="text-lg  w-full mb-4 font-medium text-[#504f4f]  flex justify-center items-center">
                เพิ่มข้อมูลบริษัท/ร้าน/บุคคล
              </h2>
              <div className="mb-2 flex flex-col gap-2 text-[#504f4f]  md:flex-row">
                <div className="mb-2 flex-1">
                  <label>ชื่อบริษัท/ชื่อร้าน/บุคคล</label>
                  <input
                    type="text"
                    name="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                {/* //firstname,lastname,phone,email,password,pltype */}
                <div className="mb-2 flex-1">
                  <label>รายละเอียดบริการ</label>
                  <input
                    type="text"
                    name="companyDiscription"
                    value={companyDiscription}
                    onChange={(e) => setCompanyDiscription(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                <div className="mb-2 flex-1">
                  <label>ที่อยู่สำหรับติดต่อ</label>
                  <input
                    type="text"
                    name="companyAddress"
                    value={companyAddress}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                <div className="mb-2 flex-1">
                  <label>โทรศัพท์</label>
                  <input
                    type="tel"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 md:flex-row">
                <div className="mb-2 flex-1">
                  <label
                    htmlFor="file"
                    className="flex gap-4 items-center text-[#504f4f]"
                  >
                    <div className="flex flex-col items-start">
                      {fileName !== null ? (
                        <img
                          src={fileName}
                          className={`w-[${windowSize[0]}px] h-[${
                            windowSize[1] * 0.5
                          }px] object-cover`}
                        />
                      ) : (
                        <IoImagesSharp size={50} color="#504f4f" />
                      )}
                      <input
                        type="file"
                        id="file"
                        onChange={handleOnChange}
                        className="hidden"
                      />
                      รูปโปรไฟล์หรือรูปหน้าร้าน
                    </div>
                  </label>
                </div>
              </div>
              {/* ----  handleOnSubmit*/}

              {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
              <div className="flex justify-end">
                <button
                  onClick={handleOnSubmit}
                  disabled={isLoading}
                  type="button"
                  className="w-full text-center bg-sky-500 text-white py-2.5 rounded-sm"
                >
                  {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
                </button>
              </div>
            </Modal>
          </div>
          {/* -------  */}
        </div>
        <div className="mt-[200px]"></div>
      </div>
      ;
    </>
  );
};

export default HomeServices;
