import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// import { logoUrl } from "../utils/baseUrl";
import axios from "axios";
import conditionText from "../utils/conditionText";
import { useNavigate } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  pvshopUrl,
  searchcompanyUrl,
  memberUrl,
  imgShopUrl,
  loginUrl,
  officesPTUrl,
} from "../constants/BaseUrl";
import { Header } from "../components";

const Policy = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [isPolicy, setIspolicy] = useState("");
  const [policyData, setPolicyData] = useState({});
  const validationSchema = Yup.object().shape({
    acceptTerms: Yup.bool().oneOf([true], "ต้องยอมรับเงื่อนไขการใช้งาน"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  function readPolicyData(type) {
    axios
      .get(`${officesPTUrl}readPByType.php/${type}`)
      .then(function (response) {
        console.log("Policy: ", response.data);
        setPolicyData(response.data);
      });
  }

  useEffect(() => {
    var user = localStorage.getItem("pvid");
    var ispolicy = localStorage.getItem("pvispolicy");
    console.log("user: ", user);
    console.log("ispolicy: ", ispolicy);
    setUserId(user);
    setIspolicy(ispolicy);
    readPolicyData(1);
  }, []);

  function onSubmit(data) {
    // display form data on success
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    handleOnSubmit(data);
    return false;
  }
  //disaccount,partneraccount
  const handleOnSubmit = (data) => {
    axios
      .post(`${memberUrl}updatepolicy.php`, {
        userId: userId,
        ispolicy: data.acceptTerms,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        navigate("/");
      });
  };
  return (
    <>
      <Header />
      <div className="overflow-y-scroll">
        {/* -------  */}
        {/*         <h1 className="mt-[6rem] text-2xl mb-2 font-semibold text-center">
          ข้อตกลงและเงื่อนไขการใช้บริการ
        </h1> */}
        <div className="p-4">
          {/*           <p
            className="mb-4 text-sm"
            dangerouslySetInnerHTML={{ __html: conditionText }}
          ></p> */}
          <p
            className="mb-4 text-sm"
            dangerouslySetInnerHTML={{
              __html: policyData?.content,
            }}
          ></p>
          <div className="mb-4">
            <p className="text-sm">
              ในการดำเนินการสมัครเข้าร่วมรับค่าโฆษณากับกิฟท์ไลค์พลัส
              ข้าพเจ้าตกลงและยินยอมให้กิฟท์ไลค์พลัส เก็บรวบรวม ใช้และ
              เปิดเผยข้อมูลที่ข้าพเจ้าให้ไว้ในนามลิงค์ของพาร์ทเนอร์ ในการรับค่า
              โฆษณา การตลาด เพื่อดำเนินการตามที่จำเป็นภายใต้นโยบายส่วน
              บุคคลของกิฟท์ไลค์พลัส ที่ข้าพเจ้าได้อ่านและเข้าใจเนื้อหาทั้งหมด
              แล้ว
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="block">
              <div className="mt-2">
                <label
                  htmlFor="acceptTerms"
                  className="inline-flex items-center text-sm"
                >
                  <input
                    name="acceptTerms"
                    type="checkbox"
                    {...register("acceptTerms")}
                    id="acceptTerms"
                    className="mr-2 w-6 h-6 text-green-600 border-0 rounded-md focus:ring-0"
                  />
                  ยอมรับเงื่อนไขการใช้งาน
                </label>
                <div className="my-3 text-sm text-red-500 italic">
                  {errors.acceptTerms?.message}
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={isPolicy != 0 ? true : false}
                className="w-full mt-4 py-2 text-lg text-white font-semibold text-center rounded-md bg-blue-500 transition-all hover:bg-blue-600 focus:outline-none"
              >
                ยอมรับเงื่อนไขการใช้งาน
              </button>
            </div>
          </form>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default Policy;
