import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgUnUrl,
  imgPvUrl,
  imgUserUrl,
} from "../constants/BaseUrl";

const UserPreview = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  return (
    <>
      <div className="relative mt-[1rem] cursor-pointer rounded-full shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
        <div className="w-[60px] h-[60px] rounded-full overflow-hidden">
          {item?.picture !== null ? (
            <>
              <img
                src={`${imgUserUrl}${item.picture}`}
                alt={item.thName}
                className="w-full h-full rounded-full object-cover"
              />
            </>
          ) : (
            <>
              <img
                src={require("../assets/images/person_color.png")}
                alt={item.thName}
                className="w-full h-full rounded-full object-cover"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserPreview;
