import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";

const RegisterMember = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [userId, setUserId] = useState(null);
  const [userAccount, setUserAccount] = useState({});
  const readAccount = (id) => {
    axios.get(`${pvuserUrl}readuser.php/${id}/pvacc`).then(function (response) {
      // console.log(response.data);
      setUserAccount(response.data);
    });
  };

  useEffect(() => {
    var id = localStorage.getItem("pvid");
    // var user = localStorage.getItem("ufullname");
    setUserId(id);
    readAccount(id);
  }, []);
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem]">
          {/* ------  */}
          <div
            className={`w-[${windowSize[0]}px] h-[${
              windowSize[1] * 0.5
            }px] rounded-lg overflow-hidden relative`}
          >
            <img
              src={require("../assets/images/no-8.png")}
              alt={""}
              className={`w-[${windowSize[0]}px] h-[${
                windowSize[1] * 0.5
              }px] object-cover`}
            />
            <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
              <strong className="text-sm text-white">ไม่มีบริการ</strong>
            </div>
          </div>
          {/* ------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default RegisterMember;
