import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, PQCarddetail } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";
import SignatureCanvas from "react-signature-canvas";
const PricequoteContactDetail = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [subject, setSubject] = useState("");
  const [yourDiscription, setYourDiscription] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const item = location.state;
  const { pqID, pqData } = item;

  /*   pqID: data.id,
  pqData: data, */
  console.log("Hi Detail: ", pqID, pqData);
  const [pqContactDataDetail, setPqContactDataDetail] = useState([]);
  const [docContactData, setDocContactData] = useState([]);

  const [selectedFile, setSectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [imageData, setImageData] = useState({
    base64textString: "",
    imageName: "",
    showImage: false,
  });
  //event
  const convertToBase64 = () => {
    // const file = event.target.files[0];
    const file = pqData?.signature;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setImageData({
        base64textString: reader.result,
        imageName: file.name,
        showImage: true,
      });
    };

    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const readPqContact = (id) => {
    axios
      .get(`${searchcompanyUrl}readservicepricequote.php/${id}`)
      .then(function (response) {
        // console.log(response.data);
        setPqContactDataDetail(response.data);
      });
  };

  useEffect(() => {
    readPqContact(pqID);
    //setFileName(URL.createObjectURL(pqData?.signature)); //.name
    // convertToBase64();
  }, []);

  console.log("PQDetail: ", pqContactDataDetail);
  console.log("Sign: ", pqData.signature);
  console.log("FileName IMG: ", fileName);
  // console.log("FileName Base64: ", fileName?.base64);
  //react-native-fetch-blob
  /*
  const getEmergencyFoundImg = urlImg => {
  var img = new Image();
  img.src = urlImg;
  img.crossOrigin = 'Anonymous';
  var canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
  canvas.height = img.naturalHeight;
  canvas.width = img.naturalWidth;
  ctx.drawImage(img, 0, 0);
  var b64 = canvas.toDataURL('image/png').replace(/^data:image.+;base64,/, '');
  return b64;
};
  */

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem]">
          <div>
            <strong>รายละเอียดใบเสนอราคา</strong>
          </div>
          <div>
            {pqData?.signature && (
              <div className="flex justify-center mb-4">
                <img src={pqData?.signature} alt={pqData?.signature} />
              </div>
            )}
          </div>
          <div className="rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden">
            <span className="text-sm font-medium">วันที่:</span>{" "}
            {pqData?.created_at}
          </div>
          <div className="rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden">
            <span className="text-sm font-medium">ชื่องาน:</span>{" "}
            {pqData?.jobname}
          </div>
          <div className="rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 overflow-hidden">
            <span className="text-sm font-medium">ลูกค้า:</span>{" "}
            {pqData?.customername}
          </div>
          <div className="rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 overflow-hidden ">
            <span className="text-sm font-medium">รายละเอียดงาน:</span>{" "}
            {pqData?.jobdetail}
          </div>
          <div className="rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 overflow-hidden">
            <span className="text-sm font-medium">ชื่อผู้บริการ:</span>{" "}
            {pqData?.providername}
          </div>
          <div className="rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 overflow-hidden">
            <span className="text-sm font-medium">ที่อยู่ผู้บริการ:</span>{" "}
            {pqData?.provideraddress}
          </div>
          <div
            className={`w-[${windowSize[0]}px] h-[${
              windowSize[0] * 0.5
            }px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden`}
          >
            <div className="flex justify-between font-semibold">
              <div>#</div>
              <div>รายการ</div>
              <div>จำนวน</div>
              <div>ราคา(บาท)</div>
              <div>รวมเงิน(บาท)</div>
            </div>
          </div>
        </div>
        {/* --------  */}
        {Object.keys(pqContactDataDetail).length !== 0 ? (
          <div className="">
            <PQCarddetail
              title="รายละเอียดใบเสนอราคา"
              data={pqContactDataDetail}
              width={windowSize}
            />
          </div>
        ) : (
          <></>
        )}
        {/* -------- PQCarddetail */}
      </div>
    </>
  );
};

export default PricequoteContactDetail;
