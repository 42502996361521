import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";

import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  shopUrl,
  apiUrl,
} from "../constants/BaseUrl";
// import axios from "axios";
// import MainOccupationCard from "./../components/MainOccupationCard";
import {
  MainOccupationCard,
  NearProviceCard,
  TopProviceCard,
  Header,
  NotificationCard,
} from "../components";
import axios from "axios";
import { FaUserCog, FaUserMinus, FaCartPlus, FaGlobe } from "react-icons/fa";
import { GrHelpBook } from "react-icons/gr";
import { GrDocumentVerified } from "react-icons/gr";
import { MdOutlineLiveHelp } from "react-icons/md";
const HomeSettings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [userAccount, setUserAccount] = useState({});
  const [apiData, setApiData] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const web = "https://giftlikesinfo.com/";
  const readApi = (country) => {
    axios.get(`${apiUrl}readapi.php/${country}`).then(function (response) {
      // console.log(response.data);
      setApiData(response.data);
    });
  };
  const readAccount = (id) => {
    axios
      .get(`${shopUrl}readuserlogin.php/${id}/pvacc`)
      .then(function (response) {
        // console.log("Member: ", response.data);
        setUserAccount(response.data);
        readApi(response.data.country);
      });
  };
  useEffect(() => {
    var user = localStorage.getItem("pvid");
    setUserId(user);
    readAccount(user);
    /*     if (user === null) {
      navigate("/signin");
    } */
  }, []);
  return (
    <>
      <Header />
      <div className="overflow-y-scroll">
        {/* -------  */}
        {userId === null ? (
          <>
            {/*               <div className="flex justify-center items-center mt-[4.5rem] p-1 py-2 bg-red-500">
            <strong className="font-medium">กรุณาเข้าระบบ</strong>
          </div>  */}
            <div className="mt-[4.5rem] mb-2">
              {/* -------  */}
              <NotificationCard title={"แจ้งเตือน"} text={"กรุณาเข้าระบบ"} />
              {/*             <NotificationCard
              title={"แจ้งเตือน"}
              text={"กรุณาป้อนข้อมูลบัตรประชาชนที่เมนูตั้งค่า"}
            /> */}
              {/* -------  */}
            </div>
          </>
        ) : null}

        <div className={`${userId !== null ? "mt-[4.5rem]" : ""} mx-4`}>
          <div className="my-1 p-2">
            <strong className="font-medium">บัญชีผู้ใช้</strong>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/userprofile" : ""}`}
              className="flex gap-4 items-center"
            >
              {/*               <FaUserCog
                fontSize={20}
                color="#FFF"
                className=" bg-sky-500 rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]"
              /> */}
              <img
                src={require("../assets/setting/1.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">บัญชีผู้ใช้งาน</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/addIDCard" : ""}`}
              className="flex gap-4 items-center"
            >
              <img
                src={require("../assets/setting/1.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />

              <strong className="font-normal">เพิ่มข้อมูลเลขบัตรประชาชน</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/deleteaccount" : ""}`}
              className="flex gap-4 items-center"
            >
              <img
                src={require("../assets/setting/2.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />

              <strong className="font-normal">ลบบัญชีผู้ใช้งาน</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/VIPPackage" : ""}`}
              className="flex gap-4 items-center"
            >
              <img
                src={require("../assets/setting/3.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">VIP Package</strong>
            </Link>
          </div>
          <div className="my-1 p-2">
            <strong className="font-medium">เกี่ยวกับ</strong>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/registerMember"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/4.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">สมัครลิงค์แนะนำอาชีพ</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/privacyPolicy"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/5.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">นโยบายความเป็นส่วนตัว</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/termOfService"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/6.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">เงื่อนไขและข้อกำหนด</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/callCenter" : ""}`}
              className="flex gap-4 items-center"
            >
              <img
                src={require("../assets/setting/7.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">ร้องเรียน</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/helpCenter"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/8.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">ช่วยเหลือ</strong>
            </Link>
          </div>
        </div>

        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default HomeSettings;
