import React from "react";
import {
  FaGlobe,
  FaYoutube,
  FaFacebookSquare,
  FaTiktok,
  FaInstagramSquare,
  FaTwitter,
} from "react-icons/fa";
const SocialMediaCard = ({ item }) => {
  return (
    <div>
      <a href={item?.url} target="_blank" className="w-[70px] items-center">
        <div className="w-[42px] h-[42px] bg-orange-400 p-2 gap-4 flex justify-center items-center rounded-full">
          {item.name === "Website" ? (
            <>
              <FaGlobe size={30} color="#FFF" />
              {/*               <FontAwesome5Pro
                name={item.icon}
                size={20}
                color={COLORS.DEFAULT_WHITE}
              /> */}
            </>
          ) : null}
          {item.name === "Facebook" ? (
            <>
              <FaFacebookSquare size={30} color="#FFF" />
            </>
          ) : null}
          {item.name === "Twitter" ? (
            <>
              <FaTwitter size={30} color="#FFF" />
            </>
          ) : null}
          {item.name === "Youtube" ? (
            <>
              <FaYoutube size={30} color="#FFF" />
            </>
          ) : null}
          {item.name === "TikTok" ? (
            <>
              <FaTiktok size={30} color="#FFF" />
            </>
          ) : null}
          {item.name === "Instagram" ? (
            <>
              <FaInstagramSquare size={30} color="#FFF" />
            </>
          ) : null}
        </div>
      </a>
    </div>
  );
};

export default SocialMediaCard;
