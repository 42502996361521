import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  loginUrl,
  memberUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, HeadAuth } from "../components";
import axios from "axios";
import Swal from "sweetalert2";

const Signin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({ email: "", password: "" });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values) => ({ ...values, [name]: value }));
  };

  const handleOnSubmit = (data) => {
    console.log("User: ", data);
    axios.post(`${memberUrl}login.php`, data).then(function (response) {
      console.log(response.data);
      if (response.data.user.length === 0) {
        console.log("ไม่พบบัญชีผู้ใช้นี้");
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "ไม่พบบัญชีผู้ใช้นี้",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      if (response.data.status === 1) {
        window.localStorage.setItem("pvid", response.data.user.id);
        window.localStorage.setItem("pvemail", response.data.user.email);
        window.localStorage.setItem("pvispolicy", response.data.user.ispolicy);
        // window.localStorage.setItem("isopen", response.data.user.isopen);
        window.localStorage.setItem(
          "pvfullname",
          response.data.user.firstname + " " + response.data.user.lastname
        );
        if (response.data.user.ispolicy !== 0) {
          navigate("/");
        } else {
          navigate("/policy");
        }
      }
    });
  };

  useEffect(() => {
    var user = localStorage.getItem("pvid");
    setUserId(user);
    if (userId === null) {
      navigate("/signin");
    } else {
      navigate("/");
    }

    // readAllProvice();
    // setIsLoading(false);
  }, [userId]);
  return (
    <>
      <div className="overflow-y-scroll">
        {/* -------  */}
        <div className="w-[24.375rem] mx-auto">
          <HeadAuth />
        </div>
        <div className="">
          {/* -------- max-w-[420px]  bg-purple-100 */}

          <div className="relative min-h-screen backdrop-blur flex justify-center items-center bg-texture bg-cover py-28 sm:py-0">
            <div className="p-4 sm:p-8 flex-1 ">
              <div className="max-w-[420px] min-w-[320px] border-stroke border-[#504f4f] rounded-lg mx-auto">
                <div className="px-2 pt-8 pb-8 rounded-3xl shadow-xl">
                  <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <div className="">
                      <label className="mb-2.5 block font-medium">
                        อีเมล์ผู้ใช้งาน
                      </label>
                      <input
                        type="email"
                        {...register("email", {
                          required: true,
                        })}
                        className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] py-3 px-5 font-medium outline-none"
                        // placeholder="willPig@tailwind.com"
                      />
                    </div>
                    {errors.email && (
                      <div className="my-1 text-normal text-red-500 text-xs italic">
                        ป้อนอีเมล์ผู้ใช้งาน
                      </div>
                    )}
                    <div className="mt-2">
                      <label className="mb-2.5 block font-medium">
                        รหัสผ่าน
                      </label>
                      <input
                        type="password"
                        {...register("password", {
                          required: true,
                        })}
                        className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] py-3 px-5 font-medium outline-none"
                        // placeholder="Password"
                      />
                    </div>
                    {errors.password && (
                      <div className="my-1 text-normal text-red-500 text-xs italic">
                        ป้อนรหัสผ่าน
                      </div>
                    )}

                    <button
                      type="submit"
                      className="w-full mt-4 py-2 text-lg text-white font-semibold text-center rounded-md bg-blue-500 transition-all hover:bg-blue-600 focus:outline-none"
                    >
                      เข้าระบบ
                    </button>
                    <p className="text-center text-sm mt-4">
                      ยังไม่มีบัญชี?{" "}
                      <Link
                        to={"/signup"}
                        className="font-semibold hover:underline"
                      >
                        คลิกสมัครที่นี่
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* --------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default Signin;
