import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
  pvshopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, PriceQuoteCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";

const ListPriceQuote = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [subject, setSubject] = useState("");
  const [yourDiscription, setYourDiscription] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const item = location.state;
  const { contactId, comId, uid, memId } = item;
  /*   contactId: contactId,
  comId: item?.id,
  uid: userId,
  memId: item?.memberID, */
  console.log("Hi Item: ", item);
  const [priceQData, setPriceQData] = useState([]);
  const [docContactData, setDocContactData] = useState([]);
  const [selectedFile, setSectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [contactInfoData, setContactInfoData] = useState([]);

  const readuserInfo = (memId) => {
    axios
      .get(`${pvshopUrl}readcontactbymemid.php/${memId}/pvnotify`)
      .then(function (response) {
        // console.log('99----<<<>>>');
        // console.log(response.data);
        // console.log(response.data.id);
        // console.log('88---->>>');
        setContactInfoData(response.data);
        // readContactFromUser(response.data?.id);
      });
  };

  function readpriceQ(conId) {
    axios
      .get(`${searchcompanyUrl}readpricequote.php/${conId}`)
      .then(function (response) {
        // console.log(response.data);
        setPriceQData(response.data);
      });
  }

  useEffect(() => {
    readuserInfo(uid);
    readpriceQ(contactId);
    // const { contactId, comId, uid, memId } = item;
  }, []);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const gotoAddPricequote = () => {
    const itemData = {
      contactId: contactId,
      comId: comId,
      uid: uid,
      memId: memId,
    };
    //contactId, comId, uid, memId
    navigate("/addPriceQuote", { state: itemData });
  };
  //PriceQuoteCard.js
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}
        {/*         <div className="mt-[4rem]">
          Contact:{contactId}, Company:{comId}, User:{uid}, Memberx:{memId}{" "}
        </div> */}
        <div className="mt-[5rem]">
          <div className="flex justify-end  px-1 mb-4">
            <button
              onClick={gotoAddPricequote}
              className="cursor-pointer px-2 py-1 bg-sky-500 text-white rounded-sm flex justify-center items-center"
            >
              <HiOutlinePlus size={20} />
              <span className="ml-1">ออกใบเสนอราคา</span>
            </button>
          </div>
          <div className="">
            <div> {/* Company:{comId}, User:{uid}, Provider:{memId} */}</div>
            {/* -----------  */}
            {/* {JSON.stringify(docContactData)} */}
            {Object.keys(priceQData).length !== 0 ? (
              <>
                {priceQData.map((pq, idx) => {
                  return (
                    <PriceQuoteCard key={idx} data={pq} width={windowSize} />
                  );
                })}
              </>
            ) : (
              <></>
            )}
            {/* -----------  */}
          </div>
          {/* -----------  */}

          {/* -----------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default ListPriceQuote;
