import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";

import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  pvshopUrl,
  govUrl,
  pvUrl,
  uUrl,
} from "../constants/BaseUrl";
import { HomeIntro, ProductFillter, ProductGrid } from "../components";
import axios from "axios";
import {
  AdvertBrand,
  AdvertProvice,
  AdvertProvider,
  UserPreview,
  Header,
} from "../components";
import { UserLocationContext } from "../context/UserLocationContext";
import { IoLocation } from "react-icons/io5";
import CookieConsent, { Cookies } from "react-cookie-consent";
const HomeDashboard = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [advertProvice, setAdvertProvice] = useState([]);
  const [advertProvider, setAdvertProvider] = useState([]);
  const [advertUnit, setAdvertUnit] = useState([]);
  const [previewUsers, setPreviewUsers] = useState([]);
  const [geocoding, setGeocoding] = useState(null);
  // const longdo_key = "9820e2a8e06a7cf53d8521d1517578dd";
  const longdo_key = "6b75448d3c0808af052906060b770d1c";
  // const [currentLocation, setCurrentLocation] = useState(null);
  /*   const place =
    "Amphoe Mueang Chiang Mai, Chang Wat Chiang Mai 50300, Thailand"; */
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const { location, setLocation } = useContext(UserLocationContext);
  const [userId, setUserId] = useState(null);
  /*   function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  } */

  // function success(position) {
  /*     const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setCurrentLocation({ latitude, longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`); */
  // Make API call to OpenWeatherMap
  /*     fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=<YOUR_API_KEY>&units=metric`)
      .then(response => response.json())
      .then(data => {
        setWeather(data);
        console.log(data);
      })
      .catch(error => console.log(error));*/
  // }

  /*   function error() {
    console.log("Unable to retrieve your location");
  } */
  //readprovice.php
  function readProviceCondition(condition) {
    // axios.get(`${govUrl}read.php/${condition}`)
    //readprovicewithlocation.php
    //geocoding.district
    axios
      .post(`${govUrl}readprovicewithlocation.php`, {
        postcode: condition,
      })
      .then(function (response) {
        // console.log("GOV Location123456: ", response.data);
        setAdvertProvice(response.data);
        setIsLoading(false);
      });
  }
  function readProviceMyCondition(latitude, longitude) {
    // handleLocationClick();
    console.log("Hello");
    console.log(`Latitude 1: ${latitude}, Longitude 1: ${longitude}`);
    // axios.get(`${govUrl}read.php/${condition}`)
    //readprovicewithlocation.php
    axios
      .post(`${govUrl}readprovicewithlocation.php`, {
        latitude,
        longitude,
      })
      .then(function (response) {
        // console.log("GOV Location: ", response.data);
        setAdvertProvice(response.data);
        setIsLoading(false);
      });
  }

  const readUserPreview = () => {
    //localhost/providerapi/providers/readpreviewcontact.php
    //`${pvshopUrl}userpreviewcontact.php/${id}`
    //export const pvshopUrl = `${url}pvshop/`;
    axios.get(`${pvshopUrl}userpreviewcontact.php`).then(function (response) {
      // console.log("Preview data: ", response.data);
      setPreviewUsers(response.data);
      setIsLoading(false);
    });
  };

  //https://api.longdo.com/map/services/address?lon=100.53726&lat=13.72427&noelevation=1&key=[YOUR-KEY-API]
  //Reverse Geocoding
  const readReverseGeocoding = () => {
    //localhost/providerapi/providers/readpreviewcontact.php
    //`${pvshopUrl}userpreviewcontact.php/${id}`
    //export const pvshopUrl = `${url}pvshop/`;
    //longdo_key${pvshopUrl}
    /*   latitude: location.latitude,
  longitude: location.longitude, */
    axios
      .get(
        `https://api.longdo.com/map/services/address?lon=${location.longitude}&lat=${location.latitude}&noelevation=1&key=${longdo_key}`
      )
      .then(function (response) {
        // console.log("Reverse Geocoding: ", response.data);
        setGeocoding(response.data);
        readProviceCondition(response.data?.postcode);
        readUnitData(response.data?.postcode);
        setIsLoading(false);
      });
  };

  /*   const latitude = position.coords.latitude;
  const longitude = position.coords.longitude; */
  // {latitude: 18.8166659, longitude: 98.979779}
  //readcompany.php
  //export const uUrl = `${url}adverts/unit/`;
  function readUnitData(condition) {
    axios
      .post(`${uUrl}readcompanypostcode.php`, {
        postcode: condition,
      })
      .then(function (response) {
        // console.log("Unit data: ", response.data);
        setAdvertUnit(response.data);
        setIsLoading(false);
      });
  }

  function readProviderData() {
    axios.get(`${pvUrl}read.php`).then(function (response) {
      // console.log(response.data);
      //advertProvider
      //export const pvUrl = `${url}adverts/provider/`;
      setAdvertProvider(response.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    // readProviceCondition(place);
    // handleLocationClick();
    /*     latitude: currentLocation.latitude,
    longitude: currentLocation.longitude, */
    // handleLocationClick();
    /*     if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCurrentLocation({ latitude, longitude });
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        readProviceMyCondition(latitude, longitude);
      });
    } else {
      console.log("Geolocation not supported");
    } */

    // readUnitData(place);
    // readProviceCondition(geocoding?.district);
    var user = localStorage.getItem("pvid");
    setUserId(user);
    if (userId === null) {
      // navigate("/");
      navigate("/signin");
    }
    readProviderData();
    readUserPreview();
    if (location) {
      readReverseGeocoding();
    }

    // readAllProvice();
    setIsLoading(false);
  }, [previewUsers]);

  /*   useEffect(() => {
    var user = localStorage.getItem("pvid");
    setUserId(user);
    if (user === null) {
        navigate("/");
      navigate("/signin");
    }
  }, []); */
  return (
    <>
      <Header />
      <div className="mt-[5rem] mx-2 flex items-center">
        <IoLocation size={20} color={"red"} />
        <strong className="">
          {geocoding?.province} {geocoding?.postcode} {geocoding?.country}
        </strong>
      </div>
      {/* <div className="mt-[6rem]"></div> */}
      <div className="overflow-y-scroll mx-3">
        {/*       <div className="mt-[6rem] mx-[1rem]">
    {Object.keys(advertProvice).length !== 0 ? (
      <>
      <AdvertProvice data={advertProvice} />
      </>
      
    ) : null}
  </div> */}
        <div className="no-scrollbar flex gap-3 overflow-x-auto">
          {/* {Object.keys(companyInfoData).length !== 0 ? (<></>):null} */}
          {Object.keys(advertProvice).length !== 0 ? (
            <>
              {advertProvice.map((item, idx) => (
                <AdvertProvice
                  key={`advertprovice_${idx}`}
                  item={item}
                  width={windowSize[0]}
                />
              ))}
            </>
          ) : null}
        </div>
        <div className="mt-[1rem]">
          <strong>ผู้ใช้งานล่าสุด</strong>
        </div>
        {/*   -{windowSize[0] * 0.5}    <div>
    <UserPreview previewdata={previewUsers} />
  </div> */}
        <div className="no-scrollbar flex gap-3 overflow-x-auto">
          {Object.keys(previewUsers).length !== 0 ? (
            <>
              {previewUsers.map((item, idx) => (
                <UserPreview key={`userpreview_${idx}`} item={item} />
              ))}
            </>
          ) : null}
        </div>
        <div className="mt-[1rem]">
          <strong>บริการแนะนำ</strong>
        </div>
        {/*       <div>
    {Object.keys(advertUnit).length !== 0 ? (
      <AdvertBrand data={advertUnit} />
    ) : null}
  </div> */}
        <div className="no-scrollbar flex gap-3 overflow-x-auto">
          {Object.keys(advertUnit).length !== 0 ? (
            <>
              {advertUnit.map((item, idx) => (
                <AdvertBrand
                  key={`advertunit_${idx}`}
                  item={item}
                  width={windowSize[0]}
                />
              ))}
            </>
          ) : null}
        </div>
        <div className="mt-[1rem]">
          <strong>บริการน่าสนใจ</strong>
        </div>
        {/*       <div>
    {Object.keys(advertProvider).length !== 0 ? (
      <AdvertProvider data={advertProvider} />
    ) : null}
  </div> */}
        <div className="no-scrollbar flex gap-3 overflow-x-auto">
          {Object.keys(advertProvider).length !== 0 ? (
            <>
              {advertProvider.map((item, idx) => (
                <AdvertProvider key={`advertprovider_${idx}`} item={item} />
              ))}
            </>
          ) : null}
        </div>
        <div className="mt-[200px]"></div>
        {/* <HomeIntro /> */}
        {/*       <ProductFillter />*/}
        {/* <ProductGrid /> */}
      </div>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="ยอมรับ"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          /*           onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              alert("Accept was triggered by user scrolling");
            } else {
              alert("Accept was triggered by clicking the Accept button");
            }
          }} */
        >
          เว็บไซต์นี้มีการใช้คุกกี้ (cookies)
          เพื่อเพิ่มประสิทธิภาพของผู้เข้าชมให้ได้รับประสบการณ์ที่ดีที่สุดจากเว็บไซต์ของเรา.{" "}
          <span style={{ fontSize: "10px" }}>
            โดยการเข้าใช้เว็บไซต์นี้ คุณได้ยินยอมในนโยบายคุกกี้ของเรา
          </span>
        </CookieConsent>
      </div>
    </>
  );
};

export default HomeDashboard;
