import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";

const DocContacts = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [subject, setSubject] = useState("");
  const [yourDiscription, setYourDiscription] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const item = location.state;
  /*   comId: item?.id,
  uid: userId,
  memId: item?.memberID, */
  const { comId, uid, memId } = item;
  console.log("Hi Item: ", item);
  const [docContactData, setDocContactData] = useState([]);
  const [selectedFile, setSectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const readDocContact = (companyId, uid, memId) => {
    axios
      .get(`${searchcompanyUrl}readdoccontact.php/${companyId}/${uid}/${memId}`)
      .then(function (response) {
        console.log("Contact Me: ", response.data);
        setDocContactData(response.data);
      });
  };

  //comId, uid, memId
  useEffect(() => {
    readDocContact(comId, uid, memId);
  }, []);

  const handleOnSubmit = () => {
    if (subject === "" || yourDiscription === "") {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "กรุณาป้อนชื่อเรื่องและรายละเอียด",
        icon: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("memId", memId);
    formData.append("companyId", comId);
    formData.append("uid", uid);
    formData.append("subject", subject);
    formData.append("description", yourDiscription);
    setIsLoading(true);
    axios
      .post(`${searchcompanyUrl}savedoccontact.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readDocContact(comId, uid, memId);
        setSubject("");
        setYourDiscription("");
        setSectedFile(null);
        onCloseModal();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsLoading(false);
      });
  };
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem]">
          <div className="flex justify-end mb-4">
            <button
              onClick={onOpenModal}
              className="cursor-pointer bg-sky-500 text-white rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]"
            >
              <HiOutlinePlus size={30} />
            </button>
          </div>
          <div className="">
            <div> {/* Company:{comId}, User:{uid}, Provider:{memId} */}</div>
            {/* -----------  */}
            {/* {JSON.stringify(docContactData)} */}
            {Object.keys(docContactData).length !== 0 ? (
              <>
                {docContactData.map((item, idx) => (
                  <div
                    key={idx}
                    className={`w-[${windowSize[0]}px] rounded-sm rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
                  >
                    <div className="flex items-center gap-2">
                      <div className="w-[20px] h-[20px] bg-red-500 rounded-full overflow-hidden mb-1"></div>
                      <div className="flex-1 w-full">
                        <p className="text-sm font-medium">{item?.subject}</p>
                        <p className="text-sm font-medium">
                          {item?.description}
                        </p>
                      </div>
                      <div>
                        {item?.docfile !== null ? (
                          <>
                            <a
                              href={`${imgDocUrl}${item?.docfile}`}
                              target="_blank"
                            >
                              <MdOutlineFileDownload
                                size={30}
                                color="#85837f"
                              />
                            </a>
                          </>
                        ) : (
                          <>
                            <MdOutlineFileDownload size={30} color="#EEE" />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}

            {/* -----------  */}
          </div>
          {/* -----------  */}
          <div>
            <Modal open={open} onClose={onCloseModal} center>
              <h2 className="text-lg mb-4 font-medium text-[#504f4f] flex justify-center items-center">
                ส่งเอกสารจัดจ้าง
              </h2>
              {/* <form onSubmit={handleOnSubmit}> */}
              <div className="mb-2 flex flex-col gap-2 text-[#504f4f] md:flex-row">
                <div className="mb-2 flex-1">
                  <label>ชื่อเรื่อง</label>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                {/* //firstname,lastname,phone,email,password,pltype */}
                <div className="mb-2 flex-1">
                  <label>รายละเอียดเพิ่มเติม</label>
                  <input
                    type="text"
                    name="yourDiscription"
                    value={yourDiscription}
                    onChange={(e) => setYourDiscription(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 md:flex-row">
                <div className="mb-2 flex-1">
                  <label
                    htmlFor="file"
                    className="flex gap-4 items-center text-[#504f4f]"
                  >
                    <div className="flex flex-col items-start">
                      <GrDocumentUpdate size={30} color="#504f4f" />
                      <input
                        type="file"
                        id="file"
                        onChange={handleOnChange}
                        className="hidden"
                      />
                      ส่งเอกสารจัดจ้าง (PDF,Word,อื่นๆ)
                      {fileName ? (
                        <>
                          <p>{fileName}</p>
                        </>
                      ) : null}
                    </div>
                  </label>
                </div>
              </div>
              {/* ----  handleOnSubmit*/}

              {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
              <div className="flex justify-end">
                <button
                  onClick={handleOnSubmit}
                  disabled={isLoading}
                  type="button"
                  className="w-full text-center bg-sky-500 text-white py-2.5 rounded-sm"
                >
                  {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
                </button>
              </div>
              {/* </form> */}
            </Modal>
          </div>
          {/* -----------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default DocContacts;
