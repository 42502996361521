import React, { useState, useEffect, useRef, useContext } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  shopUrl,
  uUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, HeaderMap } from "../components";
import axios from "axios";
import { UserLocationContext } from "../context/UserLocationContext";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
// import { IoImagesSharp } from "react-icons/io5";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoImagesSharp } from "react-icons/io5";
/* import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md"; */
const ShopLocation = () => {
  const { id } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();
  // const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const inputReference = useRef(null);
  const [address, setAddress] = useState(null);
  const [road, setRoad] = useState(null);
  const [tambon, setTambon] = useState(null);
  const [Amphur, setAmphur] = useState(null);
  const [province, setProvince] = useState("");
  const [pvince, setVince] = useState(null);
  const [country, setCountry] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [roadLat, setRoadLat] = useState(null);
  const [roadLon, setRoadLon] = useState(null);

  const { location, setLocation } = useContext(UserLocationContext);
  const [geocoding, setGeocoding] = useState(null);
  const [geocodingLocation, setGeocodingLocation] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [searchLocation, setSearchLocation] = useState(location);
  // const longdo_key = "9820e2a8e06a7cf53d8521d1517578dd";
  const longdo_key = "6b75448d3c0808af052906060b770d1c";
  const [locations, setLocations] = useState([]);
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  //105/431 Nawamin Rd, Nawamin, Bueng Kum, Bangkok 10230
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  /*
      marker = new window.longdo.Marker(
      {
        lon: location?.longitude,
        lat: location?.latitude,
      },
      {
        title: "Marker",
        // detail: "Drag me",
        draggable: true,
      }
    );
  */
  // const mapRef = useRef();
  //latitude: 18.8168254
  //longitude: 98.9907339
  var map;
  var marker;
  var marker2;
  var longdo = window.longdo;
  const mapCallback = () => {
    console.log("mapCallback: ", searchLocation);
    // inputReference.current.focus();
    // var currentLocation = map.location();
    // console.log("Location mapCallback: ", location);
    //{lon: 98.99211228493506, lat: 18.81642135851569}
    //273 ถ.ป่าตัน ต.ป่าตัน อ.เมืองเชียงใหม่ จ.เชียงใหม่ 50300
    //{lon: 98.99370767176151, lat: 18.8298221084872}
    // console.log("Longdo object: ", longdo);
    /*     {
      lon: location?.longitude,
      lat: location?.latitude,
    }, */
    /*       {
        lon: 100.64842293068324,
        lat: 13.787791382430747,
      }, */

    //13.787791382430747, 100.64842293068324
    /*            lon: location?.longitude,
        lat: location?.latitude,  */
    marker = new window.longdo.Marker(
      {
        lon: searchLocation?.lon ? searchLocation?.lon : location?.longitude,
        lat: searchLocation?.lat ? searchLocation?.lat : location?.latitude,
      },
      {
        title: "Marker 1",
        // detail: "Drag me",
        draggable: true,
      }
    );
    /*     marker2 = new window.longdo.Marker(
      {
        lon: searchLocation?.lon ? searchLocation?.lon : location?.longitude,
        lat: searchLocation?.lat ? searchLocation?.lat : location?.latitude,
      },
      {
        title: "Marker 2",
        draggable: true,
      }
    ); */
    /*     lon: location?.longitude,
    lat: location?.latitude, */
    map = new window.longdo.Map({
      zoom: 12,
      location: {
        lon: searchLocation?.lon ? searchLocation?.lon : location?.longitude,
        lat: searchLocation?.lat ? searchLocation?.lat : location?.latitude,
      },
      placeholder: document.getElementById("longdo-map"),
      lastView: false,
    });
    map.Event.bind("ready", function () {
      let location = map.location(); // Cross hair location
      // console.log("Ready Location: ", location);
      // map.location()
      // console.log("Map Ready");
    });
    /*     map.Event.bind("overlayClick", function (overlay) {
      console.log(overlay);
    }); */
    /*     map.Event.bind(longdo.EventName.OverlayClick, function (overlay) {
      console.log("overlay");
    }); */
    /*     location: {
      lon: location?.longitude,
      lat: location?.latitude,
    }, */

    /*     location: {
      lon: 100.64842293068324,
      lat: 13.787791382430747,
    }, */

    map.Event.bind(longdo.EventName.OverlayClick, function (overlay) {
      var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("OverlayClick: ", mouseLocation);
      console.log(overlay);
    });
    //OverlayDrop
    map.Event.bind(longdo.EventName.OverlayDrop, function (overlay) {
      var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("OverlayDrop: ", mouseLocation);
      setSearchLocation(mouseLocation);
      /*       console.log("OverlayDrop: ", overlay);
      console.log("lon: ", mouseLocation.lon);
      console.log("lat: ", mouseLocation.lat); */
      findOverlayDrop(mouseLocation.lon, mouseLocation.lat);
      //13.787791382430747, 100.64842293068324
      // findOverlayDrop(100.64842293068324, 13.787791382430747);
    });
    map.Event.bind(longdo.EventName.OverlayMove, function (overlay) {
      // var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("Mouse locationxxx: ", mouseLocation);
      // setSearchLocation(mouseLocation);
      /*       if (mouseLocation) {
        readGeocoding();
      } */
    });
    // map.location(new window.longdo.LocationMode.Geolocation());
    map.Overlays.add(marker);
    // map.Overlays.add(marker2);
  };
  /*
  map.Event.bind(longdo.EventName.Click, function() {
  var mouseLocation = map.location(longdo.LocationMode.Pointer);
  map.Overlays.add(new longdo.Marker(mouseLocation));
});
map.Event.bind(longdo.EventName.OverlayClick, function(overlay) {
    console.log(overlay)
});

            map.Event.bind("ready", function () {
            map.Route.placeholder(document.getElementById('result'));
            map.Route.add(Marker);
            map.Route.add({ lon: 100, lat: 15 });
            map.Route.search();
          })
  https://search.longdo.com/smartsearch/json/search?keyword=Silom%20Bangrak%20Bangkok&key=fortestonlydonotuseinproduction!
  map.location(longdo.LocationMode.Geolocation);
  longdo.LocationMode.Geolocation

  https://search.longdo.com/addresslookup/api/addr/geocoding?text=Sathorn%20Square%20Office%20Tower,%20Sathorn%20Nuea%20Rd.,%20Silom,%20Bangrak,%20Bangkok&key=fortestonlydonotuseinproduction!

  */
  /*   const initMap = () => {language: "en",
    map.Layers.setBase(longdo.Layers.GRAY);
  };
 */
  /*   useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCurrentLocation({ latitude, longitude });
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      });
    } else {
      console.log("Geolocation not supported");
    }
  }, [currentLocation]);  */

  const findOverlayDrop = (lon, lat) => {
    //https://api.longdo.com/map/services/address?lon=100.53726&lat=13.72427&noelevation=1&key=[YOUR-KEY-API]
    axios
      .get(
        `https://api.longdo.com/map/services/address?lon=${lon}&lat=${lat}&noelevation=1&key=${longdo_key}`
      )
      .then(function (response) {
        // console.log("Reverse Geocoding: ", response.data);
        setGeocoding(response.data);
        /*         readProviceCondition(response.data?.postcode);
        readUnitData(response.data?.postcode); */
        // setIsLoading(false);
        //  setAddress(response.data)
        setRoad(response.data.road);
        setTambon(response.data.subdistrict);
        setAmphur(response.data.district);
        setProvince(response.data.province);
        // setVince(response.data)
        setCountry(response.data.country);
        setPostCode(response.data.postcode);
        setRoadLat(response.data.road_lat ? response.data.road_lat : lat);
        setRoadLon(response.data.road_lon ? response.data.road_lon : lon);
        /*        const [ road_lat, setroad_lat] = useState(null);
        const [road_lon, setroad_lon] = useState(null); 
          const [roadLat, setRoadLat] = useState(null);
  const [roadLon, setRoadLon] = useState(null);
        */
        inputReference.current.focus();
      });
  };
  const readReverseGeocoding = () => {
    //localhost/providerapi/providers/readpreviewcontact.php
    //`${pvshopUrl}userpreviewcontact.php/${id}`
    //export const pvshopUrl = `${url}pvshop/`;
    //longdo_key${pvshopUrl}
    /*   latitude: location.latitude,
  longitude: location.longitude, */
    //https://api.longdo.com/map/services/address?lon=100.53726&lat=13.72427&noelevation=1&key=[YOUR-KEY-API]
    axios
      .get(
        `https://api.longdo.com/map/services/address?lon=${location.longitude}&lat=${location.latitude}&noelevation=1&key=${longdo_key}`
      )
      .then(function (response) {
        // console.log("Reverse Geocoding: ", response.data);
        setGeocoding(response.data);
        /*         readProviceCondition(response.data?.postcode);
        readUnitData(response.data?.postcode); */
        setIsLoading(false);
      });
  };

  const readGeocoding = () => {
    axios
      .get(
        `https://api.longdo.com/map/services/address?lon=${searchLocation?.lon}&lat=${searchLocation?.lat}&noelevation=1&key=${longdo_key}`
      )
      .then(function (response) {
        // console.log("Geocoding: ", response.data);
        setGeocodingLocation(response.data);
        /*         readProviceCondition(response.data?.postcode);
      readUnitData(response.data?.postcode); */
        setIsLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;

    searchCompany(searchTerm);
    setSearchItem(searchTerm);
    // inputReference.current.focus();
  };

  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");
    // inputReference.current.focus();
    /*     if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://api.longdo.com/map/?key=${longdo_key}`;
      script.id = "longdoMapScript";
      document.body.appendChild(script);

      script.onload = () => {
        mapCallback();
        console.log(map);
      };
    } */

    if (existingScript) mapCallback();

    if (location) {
      // readReverseGeocoding();
    }
    //----ช่างชนิดแบตเตอรี่

    // Thanon Pracha Songkhro, Din Daeng, ดินแดง Bangkok 10400
    /*     อู่ช่างนิท
Thanon Pracha Songkhro, Din Daeng, ดินแดง Bangkok 10400 */
    // https://maps.app.goo.gl/Zkie75xh92Jftbss9
    //13.769124764416375, 100.55831594417702
    // findOverlayDrop(100.55831594417702, 13.769124764416375);
    // ++++++++++++
    // --ช่างเล็กกุญแจ
    //Soi Plang Anuson, Khwaeng Chom Thong, Khet Chom Thong, Krung Thep Maha Nakhon 10150
    /* Soi Plang Anuson
Soi Plang Anuson, Khwaeng Chom Thong, Khet Chom Thong, Krung Thep Maha Nakhon 10150 */
    // https://maps.app.goo.gl/tKf7a8ACpKykZQqY6
    //13.691010815882336, 100.4808721711646
    // findOverlayDrop(100.4808721711646, 13.691010815882336);

    //--ร้านเหล็กสแตนเลส

    // findOverlayDrop(100.5037282036957, 13.632181284646645);

    //--N.K. Solar
    // รีจัส เอสเจ อินฟินิท 1 บิสซิเนส คอมเพล็กซ์ 349 Vibhavadi Rangsit Rd, Chom Phon, Chatuchak, Bangkok 10900

    /*     บริษัทเอ็นเค กรุ๊ป 2019 จำกัด
รีจัส เอสเจ อินฟินิท 1 บิสซิเนส คอมเพล็กซ์ 349 Vibhavadi Rangsit Rd, Chom Phon, Chatuchak, Bangkok 10900 */

    // https://maps.app.goo.gl/xKyQagxjr7K7k4698
    //13.811836727696868, 100.55964378465836
    // findOverlayDrop(100.55964378465836, 13.811836727696868);

    //--ป.ช่างยนต์
    // 132/1 Rama VI Soi 8, Khwaeng Rong Muang, Pathum Wan, Bangkok 10330

    /*     Por. Changyon Garage (Chang Noi)
132/1 Rama VI Soi 8, Khwaeng Rong Muang, Pathum Wan, Bangkok 10330 */
    //maps.app.goo.gl/yb7yY2EJ2AtNGmE67/
    //13.746937154910924, 100.52147092883538
    // https: findOverlayDrop(100.52147092883538, 13.746937154910924);

    //------โกมลรวมช่าง
    // 72/20 ซอย สีหบุรานุกิจ 1 Sihaburanukit Rd, Min Buri, Bangkok 10510

    /*     Komol Auto Mechanics
72/20 ซอย สีหบุรานุกิจ 1 Sihaburanukit Rd, Min Buri, Bangkok 10510 */

    // https://maps.app.goo.gl/xdsakpDcjPxPj98j9

    // 13.813298798846452, 100.72113884232918
    // findOverlayDrop(100.72113884232918, 13.813298798846452);
    ////

    //---วันเพ็ญแอร์
    // 491 ซอย สำเพ็ง 2 ซอย 3 Bang Khae, Bangkok 10160
    /*     บริการล้างแอร์
491 ซอย สำเพ็ง 2 ซอย 3 Bang Khae, Bangkok 10160 */
    // https://maps.app.goo.gl/cb3Ra6CZks4ChLKz6
    //13.68434286801254, 100.4152232909321
    // findOverlayDrop(100.4152232909321, 13.68434286801254);

    ///////////

    // ---นายช่างใหญ่ ok
    // 90 Pracha Uthit Rd, Khwaeng Thung Khru, Khet Thung Khru, Krung Thep Maha Nakhon 10140
    //13.632181284646645, 100.5037282036957
    // findOverlayDrop(100.5037282036957, 13.632181284646645);

    // --------ช่างโย
    // Bang Bua Thong, Bang Bua Thong District, Nonthaburi 11110
    // 13.92126075433884, 100.42821768538856
    // findOverlayDrop(100.42821768538856, 13.92126075433884);

    //------------บางทุนเทียนอลูมิเนียม
    // บ้านเลขที่7 Bang Khun Thian 14, Samae Dam, Bang Khun Thian, Bangkok 10150
    // 13.662901477407056, 100.42685716931675
    // findOverlayDrop(100.42685716931675, 13.662901477407056);

    // findOverlayDrop(100.64842293068324, 13.787791382430747);
    /*     const script = document.createElement("script");

    script.src = `https://api.longdo.com/map3/?key=${longdo_key}`;
    script.async = true;

    document.body.appendChild(script);
    if (typeof window === "object") {
      // Check if document is finally loaded
      document.addEventListener("DOMContentLoaded", function () {
        // *** VANILLA JAVASCRIPT CODE FOR THE IMAGE GALLERIE ***
        console.log("Now");
      });
    }

    return () => {
      document.body.removeChild(script);
    }; */
    // if (existingScript && callback) callback();
    // initMap();
    // alert("Hello");
    // const handleLoad = () => {
    // Perform actions after the component has fully loaded
    // };
    /*     window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("load", handleLoad);
    };
    const divElement = mapRef.current;
    console.log(divElement); */
  }, []);
  // console.log("Location: ", location);
  function searchCompany(txtOcc) {
    setIsLoading(true);
    /*
  https://search.longdo.com/smartsearch/json/search?keyword=Silom%20Bangrak%20Bangkok&key=fortestonlydonotuseinproduction!
  map.location(longdo.LocationMode.Geolocation);
  longdo.LocationMode.Geolocation

  https://search.longdo.com/addresslookup/api/addr/geocoding?text=Sathorn%20Square%20Office%20Tower,%20Sathorn%20Nuea%20Rd.,%20Silom,%20Bangrak,%20Bangkok&key=fortestonlydonotuseinproduction!
{"geocode":"100402","country":"ประเทศไทย","province":"กรุงเทพมหานคร","district":"เขตบางรัก","subdistrict":"แขวงสีลม","postcode":"10500","aoi":"อาคารสาทรสแควร์","lon":100.52932,"lat":13.722642,"elevation":9,"road":"ถนนนราธิวาสราชนครินทร์",
"road_lon":100.529633,"road_lat":13.722795,"road_char":5}

https://search.longdo.com/addresslookup/api/addr/geocoding?text=Sathorn%20Square%20Office%20Tower,%20Sathorn%20Nuea%20Rd.,%20Silom,%20Bangrak,%20Bangkok&key=fortestonlydonotuseinproduction!
  
https://search.longdo.com/mapsearch/json/search?keyword=hotel&key=fortestonlydonotuseinproduction!

https://search.longdo.com/smartsearch/json/search?keyword=Silom%20Bangrak%20Bangkok&key=fortestonlydonotuseinproduction!
https://search.longdo.com/mapsearch/json/search?keyword=hotel&key=fortestonlydonotuseinproduction!

https://search.longdo.com/addresslookup/api/addr/geocoding?text=Sathorn%20Square%20Office%20Tower,%20Sathorn%20Nuea%20Rd.,%20Silom,%20Bangrak,%20Bangkok&key=fortestonlydonotuseinproduction!

{lon: 98.99194205851146, lat: 18.81874050219259}
{lon: 98.99194205851146, lat: 18.81874050219259}
{lon: 101.09863539706575, lat: 18.71123738712958}
317 แม่แรม Mae Rim District, Chiang Mai 50180
*/
    // GET https://search.longdo.com/addresslookup/api/addr/geocoding?text=98%20อาคารสาทร%20สแควร์%20ออฟฟิศ%20ทาวเวอร์%20ชั้น%204%20ห้อง%20407%20ถนนสาทรเหนือ%20แขวงสีลม%20เขตบางรัก%20กรุงเทพมหานคร%2010500&key=[YOUR-KEY-API]
    axios
      .get(
        `https://search.longdo.com/addresslookup/api/addr/geocoding?text=${txtOcc}&key=${longdo_key}`
      )
      .then(function (response) {
        /*         console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data); */
        // console.log("Search Localtion: ", response?.data?.data[0]?.point[0]);
        // console.log("---------Search");
        setSearchLocation(response?.data?.data[0]?.point[0]);
        if (typeof response?.data?.data[0]?.point[0] !== "undefined") {
          // console.log("undefined123");

          findOverlayDrop(
            response?.data?.data[0]?.point[0].lon,
            response?.data?.data[0]?.point[0].lat
          );
          mapCallback();
          // inputReference.current.focus();
        } else {
          // inputReference.current.focus();
        }

        // if(myStr === undefined){}
        // if(typeof myArr[7] === "undefined"){}
        // if(user.hobby === void 0){}

        /*         */
        // setCompanyInfoData(response?.data);
        // memberID
        // readLogo(response?.data.memberID);
        setIsLoading(false);
      });
  }
  // console.log("MY PROVIDER LOCATION: ", location);
  function readLocation() {
    axios.get(`${uUrl}location.php`).then(function (response) {
      // console.log(response.data);
      setLocations(response.data);
    });
  }
  useEffect(() => {
    var id = localStorage.getItem("pvid");
    var user = localStorage.getItem("pvfullname");
    // inputReference.current.focus();
    /*     if (user === null) {
      navigate("/signin");
    } */
    // console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    readLocation();
    // readAccount(id);
  }, []);
  const onSend = async () => {
    setIsLoading(true);
    try {
      const data = {
        id: id,
        memid: userId,

        address: address,
        road: road,
        tambon: tambon,
        amphur: Amphur,
        province: province,
        // provinceth: pvince,
        country: country,
        zipCode: postCode,
        roadLat: roadLat,
        roadLon: roadLon,
        // updatepvlocation.php
      };
      /*       const [address, setAddress] = useState(null);
      const [road, setRoad] = useState(null);
      const [tambon, setTambon] = useState(null);
      const [Amphur, setAmphur] = useState(null);
      const [province, setProvince] = useState("");
      const [pvince, setVince] = useState(null);
      const [country, setCountry] = useState(null);
      const [postCode, setPostCode] = useState(null);
      const [roadLat, setRoadLat] = useState(null);
      const [roadLon, setRoadLon] = useState(null); */
      await axios
        .post(`${shopUrl}updatepvlocation.php`, data)
        .then((response) => {
          console.log(response.data);
          onCloseModal();
          setAddress(null);
          setRoad(null);
          setTambon(null);
          setAmphur(null);
          setProvince("");

          setCountry(null);
          setPostCode(null);
          setRoadLat(null);
          setRoadLon(null);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <HeaderMap />
      {/* <div>{JSON.stringify(currentLocation)}</div> */}
      <div className="overflow-y-scroll">
        {/* ------- w-[2.75rem]  h-[2.75rem] */}
        <div className="mt-[5rem]">
          <div className="absolute right-0 bottom-[10rem] z-20 mb-4 mr-2">
            {geocoding !== null ? (
              <>
                <button
                  onClick={onOpenModal}
                  className="cursor-pointer  w-[100px] py-2 bg-sky-500 text-white rounded-full flex justify-center items-center"
                >
                  {/* <HiOutlinePlus size={30} /> */}
                  บันทึกที่ตั้ง
                </button>
              </>
            ) : (
              <>
                <div className="mx-2 py-2 px-4 bg-red-500 text-white">
                  ลาก Marker หรือค้นหา เพื่อระบุที่ตั้ง
                </div>
              </>
            )}
          </div>
          <div className="">
            <input
              ref={inputReference}
              type="search"
              className="w-full border py-2 px-3 text-black"
              value={searchItem}
              onChange={handleInputChange}
              placeholder={`ค้นหาที่อยู่`}
            />
          </div>
          <div
            className={`w-[${windowSize[0]}px] h-[${windowSize[1]}px] overflow-hidden mb-1`}
          >
            {/* <LongdoMap id="longdo-map" mapKey={longdo_key} callback={initMap} /> */}
            <div
              id="longdo-map"
              className={`w-[${windowSize[0]}px] h-[600px] text-black`}
            ></div>
            {/* JSON.stringify(searchLocation) <div className="mx-2 mt-2 border-t">{JSON.stringify(location)}</div> */}
            <div className="mx-2 mt-2">
              {searchLocation ? (
                <>
                  Lat: {searchLocation.lat} <br /> Lon: {searchLocation.lon}
                </>
              ) : null}
            </div>
            {/*             <div className="mx-2 mt-2">
              3: {JSON.stringify(geocodingLocation)}
            </div> district*/}
            <div>
              {geocoding !== null ? (
                <>
                  <div className="mx-2">
                    {geocoding?.road} {geocoding?.subdistrict}{" "}
                    {geocoding?.district} {geocoding?.province}{" "}
                    {geocoding?.postcode} {geocoding?.country}
                  </div>
                </>
              ) : (
                <>
                  <div className="mx-2">ลาก Marker เพื่อระบุที่ตั้ง</div>
                </>
              )}
            </div>
            {/* <LongdoMap id={"longdo-map"} mapKey={longdo_key} callback={initMap} /> */}
          </div>
        </div>
        {/* ---------  */}
        <div>
          <Modal open={open} onClose={onCloseModal} center>
            <h2 className="text-lg mb-4 text-[#504f4f] font-medium flex justify-center items-center">
              กำหนดรายละเอียดที่ตั้งร้านค้า
            </h2>
            {/* <form onSubmit={handleOnSubmit}> */}
            <div className="mb-2 flex gap-2 text-[#504f4f]">
              <div className="mb-2 flex-1">
                <label>บ้านเลขที่</label>
                <input
                  type="text"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-2 flex-1">
                <label>ถนน.</label>
                <input
                  type="text"
                  name="road"
                  value={road}
                  onChange={(e) => setRoad(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="mb-2 flex gap-2 text-[#504f4f]">
              <div className="mb-2 flex-1">
                <label>ตำบล/แขวง</label>
                <input
                  type="text"
                  name="tambon"
                  value={tambon}
                  onChange={(e) => setTambon(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-2 flex-1">
                <label>อำเถอ/เขต</label>
                <input
                  type="text"
                  name="Amphur"
                  value={Amphur}
                  onChange={(e) => setAmphur(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="mb-2 flex gap-2 text-[#504f4f]">
              <div className="mb-2 flex-1">
                <label>จังหวัด</label>
                <input
                  type="text"
                  name="province"
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {/*                 <select
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {locations.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.thName}
                      {item.enName ? `(${item.enName})` : ""}-{item.id}
                    </option>
                  ))}
                </select> */}
                {/*                 <input
                  type="text"
                  name="province"
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                /> */}
              </div>
            </div>
            <div className="mb-2 flex gap-2 text-[#504f4f]">
              <div className="mb-2 flex-1">
                <label>รหัสไปรษนีย์</label>
                <input
                  type="text"
                  name="postCode"
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-2 flex-1">
                <label>ประเทศ</label>
                <input
                  type="text"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            {/* ----  handleOnSubmit*/}

            {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
            <div className="flex justify-end">
              <button
                onClick={onSend}
                disabled={isLoading}
                type="button"
                className="w-full text-center bg-sky-500 text-white py-2.5 rounded-sm"
              >
                {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
            {/* </form> */}
          </Modal>
        </div>
        {/* ---------  */}
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default ShopLocation;
