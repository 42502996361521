import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import { IoImagesSharp } from "react-icons/io5";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import ReactStarsRating from "react-awesome-stars-rating";

const Ratings = () => {
  // const { id,  } = useParams();
  const { memId, comId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [rating, setRating] = useState([]);
  const [value, setValue] = useState(0);
  const [userId, setUserId] = useState("");

  const onChange = (value) => {
    console.log(`React Stars Rating value is ${value}`);
    setValue(value);
  };
  //readrating.php
  const readRating = (uid, memId, comId) => {
    axios
      .get(`${searchcompanyUrl}readrating.php/${uid}/${memId}/${comId}/pvmycom`)
      .then(function (response) {
        console.log("Rating: ", response.data);
        setRating(response.data);
      });
  };

  useEffect(() => {
    var uid = localStorage.getItem("pvid");
    setUserId(uid);
    // readChats(uid, memId, comId);
    readRating(uid, memId, comId);
  }, []);
  console.log("Hi Chat : ", memId, "/".comId);
  const onSaveRating = async () => {
    try {
      axios
        .post(`${searchcompanyUrl}saverating.php`, {
          companyId: comId,
          uid: userId,
          memId: memId,
          rating: value,
          who: 1,
        })
        .then(function (response) {
          console.log(response.data);
          readRating(userId, memId, comId);
          setValue(0);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll">
        {/* -------  */}

        <div className="mt-[6rem] mx-2">
          <div className="w-full flex flex-row justify-center">
            <ReactStarsRating
              onChange={onChange}
              size={50}
              starGap={15}
              isArrowSubmit={true}
              value={value}
              className="flex"
            />

            {/* <Rating style={{}} className="starRatingWrap" />text-sm font-medium flex justify-center flex-col items-center */}
          </div>
          <div className="text-center my-2">{value}/5</div>
          <div className="w-full flex justify-center items-center">
            <button
              onClick={onSaveRating}
              className="w-[200px] text-center bg-sky-500 text-white py-2.5 rounded-sm"
            >
              บันทึกข้อมูล
            </button>
          </div>
          <div className="flex flex-wrap justify-between gap-2 mt-4">
            {rating.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="w-[70px] text-center py-4 my-2 border-[1.5px] border-stroke border-[rgb(241 245 249)] shadow-[0px_4px_14px_rgba(0,0,0,0.04)]"
                >
                  <strong>{item?.rating}</strong>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-[200px]"></div>

        {/* -------  */}
      </div>
    </>
  );
};

export default Ratings;
