import React, { useState, useEffect } from "react";

import { officesFaqUrl } from "../constants/BaseUrl";
import QuestionMarkIcn from "../assets/img/faq/icons/question-mark.svg";
import Accordion from "./Accordion";
import axios from "axios";

const Faq = () => {
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${officesFaqUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);
  return (
    <div>
      {/* -------  */}
      {/* accordion list */}
      <div className="flex flex-col gap-y-4 px-4 w-full overflow-y-scroll">
        {dataApp.map((accordion, idx) => {
          return <Accordion accordion={accordion} key={idx} />;
        })}
      </div>
      {/* -------  */}
    </div>
  );
};

export default Faq;
