import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgGovUrl,
  imgShopUrl,
} from "../constants/BaseUrl";

const CompanyCard = ({ item, width }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  return (
    <div
      className={`w-[${width}px] h-[${
        width * 0.5
      }px] rounded-sm border border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
    >
      {/* -----------  */}
      <div className="flex flex-row gap-4 py-2">
        <div className="w-[80px] h-[80px] rounded-full overflow-hidden mb-1">
          {item?.companyPhoto !== null ? (
            <>
              <img
                src={`${imgShopUrl}${item.companyPhoto}`}
                alt={item.companyPhoto}
                className="w-[80px] h-[80px] rounded-full object-cover"
              />
            </>
          ) : (
            <>
              <img
                src={require("../assets/images/person_color.png")}
                alt={""}
                className="w-[80px] h-[80px] rounded-full object-cover"
              />
            </>
          )}
        </div>
        <div className="flex-1 w-full">
          <p className="text-sm font-medium">
            {item?.mainOcc ? item?.minorOcc : "ไม่ได้ระบุอาชีพ"}
          </p>
          <p className="font-medium">{item?.companyName}</p>
          <p className="text-xs">{item?.companyDetail}</p>
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-end mb-2">
        <Link
          to={`/companyprofile/${item?.id}`}
          className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
        >
          จัดการร้านค้า
        </Link>
        <Link
          to={"/editprofile"}
          className="w-[100px] text-center bg-red-500 text-white py-1 rounded-sm"
        >
          ลบร้านค้า
        </Link>
        {/*               <button
            onClick={() => {}}
            className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
          >
            แก้ไขโปรไฟล์
          </button> */}
      </div>
      {/* -----------  */}
    </div>
  );
};

export default CompanyCard;
