import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
  shopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, OccupationCard, GalleryCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import { IoImagesSharp } from "react-icons/io5";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoSaveOutline } from "react-icons/io5";

const ShopGallery = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [userId, setUserId] = useState("");
  const [companyInfoData, setCompanyInfoData] = useState({});
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSectedFile] = useState(null);
  const [galleryInfo, setGalleryInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  //   const [gallery, setGallery] = useState([]);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    if (e.target.files[0]) {
      setSectedFile(e.target.files[0]);
      setFileName(URL.createObjectURL(e.target.files[0])); //.name
      console.log("IMG: ", e.target.files[0]);
    }
  };
  function readCompany(id) {
    axios.get(`${shopUrl}readowncompany.php/${id}`).then(function (response) {
      console.log("Company: ", response.data);
      setCompanyInfoData(response.data); //"Company: ",
    });
  }

  function readGallery(id) {
    axios.get(`${shopUrl}readgallery.php/${id}`).then(function (response) {
      console.log("Gallery: ", response.data);
      setGalleryInfo(response.data);
    });
  }
  useEffect(() => {
    var user = localStorage.getItem("pvid");
    var open = localStorage.getItem("isopen");
    setUserId(user);
    readGallery(id);
    readCompany(id);
    /*     if (user === null) {
      navigate("/signin");
    } */
  }, []);

  const onSaveData = async () => {
    try {
      if (fileName === null) {
        Swal.fire({
          title: "แจ้งเตือน",
          text: "กรุณาเลือกภาพที่ต้องการ",
          icon: "error",
        });
        return;
      }
      // Alert.alert('แจ้งเตือน', photoUrl);
      // console.log(photoUrl);
      setIsLoading(true);
      let formData = new FormData();
      formData.append("fileToUpload", selectedFile);
      formData.append("memid", userId);
      formData.append("companyId", id);

      await axios
        .post(`${shopUrl}addgallery.php`, formData)
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          readGallery(id);
          readCompany(id);
          setFileName(null);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[4rem]">
          {/* --------  */}
          <div className="mb-2 flex-1 relative">
            <label
              htmlFor="file"
              className="flex gap-4 items-center text-white"
            >
              <div className="flex flex-col items-start">
                {fileName !== null ? (
                  <img
                    src={fileName}
                    className={`w-[${windowSize[0]}px] h-[${
                      windowSize[1] * 0.5
                    }px] object-cover`}
                  />
                ) : (
                  <>
                    <img
                      src={require("../assets/images/AA2.png")}
                      alt={""}
                      className={`w-[${windowSize[0]}px] h-[${
                        windowSize[1] * 0.5
                      }px] object-cover`}
                    />
                  </>
                )}
                <input
                  type="file"
                  id="file"
                  onChange={handleOnChange}
                  className="hidden"
                />
              </div>
              <div className="absolute right-1 top-1.5 flex gap-2 cursor-pointer">
                <IoImagesSharp size={30} color="#fff" />
              </div>
            </label>
            <div className="absolute right-1 bottom-1.5 flex gap-2">
              {fileName !== null ? (
                <>
                  {isLoading ? (
                    <div className="bg-sky-500 text-white rounded-sm p-2">
                      กรุณารอซักครู่..
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={onSaveData}
                        disabled={isLoading}
                        className="cursor-pointer bg-sky-500 rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]"
                      >
                        <IoSaveOutline size={30} color="#fff" />
                      </button>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
          {/* --------  */}
          <div className="grid grid-cols-3 overflow-hidden">
            {Object.keys(galleryInfo).length !== 0 ? (
              <>
                {galleryInfo.map((item, idx) => (
                  <GalleryCard key={idx} item={item} />
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
          {/* --------  */}
        </div>
        <div className="mt-[200px]"></div>
      </div>
    </>
  );
};

export default ShopGallery;
