import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";

const DocCardList = ({ item, width }) => {
  //contactDetail
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // const [userId, setUserId] = useState("");
  const gotoDocContact = () => {
    const itemData = {
      contactId: item?.id,
      comId: item?.comId,
      uid: item?.userId,
      memId: item?.memberId,
    };
    navigate("/contactDetail", { state: itemData });
  };
  return (
    <div
      className={`w-[${width[0]}px] rounded-sm border-[1.5px] border-stroke border-[rgb(241 245 249)] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
    >
      {/* <div>{JSON.stringify(item)}</div> */}
      <div className="flex items-center gap-2">
        <div className="w-[20px] h-[20px] bg-red-500 rounded-full overflow-hidden mb-1"></div>
        <div className="flex-1 w-full">
          <p className="text-sm font-medium">{item?.subject}</p>
          <p className="text-sm font-medium">{item?.description}</p>
        </div>
        <div>
          <div className="flex flex-row gap-4 justify-end">
            {/*    <Link
                to={"/editprofile"}
                className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
              >
                แก้ไขโปรไฟล์
              </Link>*/}
            <button
              onClick={gotoDocContact}
              className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
            >
              ติดต่อจ้างงาน
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocCardList;
