import React, { useEffect, useState, useContext } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";

import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  shopUrl,
  imgShopUrl,
} from "../constants/BaseUrl";
import axios from "axios";
// import MainOccupationCard from "./../components/MainOccupationCard";
import {
  MainOccupationCard,
  NearProviceCard,
  TopProviceCard,
  Header,
  CompanyCard,
  HeaderBack,
} from "../components";
import { UserLocationContext } from "../context/UserLocationContext";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { HiOutlinePlus } from "react-icons/hi";
import { IoImagesSharp } from "react-icons/io5";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { GrDocumentPdf } from "react-icons/gr";
import { FaRegStar, FaRegIdCard } from "react-icons/fa";
import { GrDocumentUpdate } from "react-icons/gr";
import { FaRegCommentDots, FaMapMarkerAlt } from "react-icons/fa";

const EditShop = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState(null);
  //   const [fileName, setFileName] = useState(null);

  const [selectedFile, setSectedFile] = useState(null);
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [companyInfoData, setCompanyInfoData] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [companyDiscription, setCompanyDiscription] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [profileImg, setProfileImg] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // This function will be triggered when the file field change
  //   const imageChange = (e) => {
  // if (e.target.files && e.target.files.length > 0) {
  //   setSelectedImage(e.target.files[0]);
  // setFileName(URL.createObjectURL(e.target.files[0]));
  //   setFileName(URL.createObjectURL(e.target.files[0])); //.name
  // }
  //   };

  const handleOnChange = (e) => {
    // console.log("----------");
    // console.log(e.target.files[0]);
    // console.log("----------");
    // setSectedFile(e.target.files[0]);
    // setFileName(e.target.files[0]);
    setSectedFile(e.target.files[0]);
    setFileName(URL.createObjectURL(e.target.files[0])); //.name
    // console.log("IMG: ", e.target.files[0]);
  };

  function readCompany(id) {
    axios
      .get(`${shopUrl}readmycompanybyid.php/${id}`)
      .then(function (response) {
        setCompanyInfoData(response.data);
        console.log("Edit Company: ", response.data);
        // /companyName,companyDetail,companycontact,companyPhoto,phone
        setCompanyName(response.data.companyName);
        setCompanyDiscription(response.data.companyDetail);
        setProfileImg(response.data.companyPhoto);
        setCompanyAddress(response.data.companycontact);
        setPhone(response.data.phone);
      });
  }

  useEffect(() => {
    readCompany(id);
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("companyName", companyName);
    formData.append("companyDetail", companyDiscription);
    formData.append("companyContact", companyAddress);
    formData.append("phone", phone);
    formData.append("id", id);
    setIsLoading(true);
    axios.post(`${shopUrl}updateshop.php`, formData).then(function (response) {
      readCompany(id);
      setCompanyName("");
      setCompanyAddress("");
      setCompanyDiscription("");
      setPhone("");
      setFileName(null);
      setSectedFile(null);
      console.log("UPDATE Company: ", response.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsLoading(false);
    });
  };

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-4">
        {/* ------- profileImg */}

        <div className="mt-[6rem]">
          <div className="w-full flex justify-center">
            {profileImg !== null ? (
              <img
                src={`${imgShopUrl}${profileImg}`}
                alt={profileImg}
                className={`w-[100px] h-[100px] rounded-full object-cover`}
              />
            ) : null}
          </div>
          <div
            className={`w-[${windowSize[0]}px] h-[${windowSize[1]}px] p-2 overflow-hidden mb-1`}
          >
            {/* ----------- onSubmit={handleSubmit(handleOnSubmit)} shadow-[0px_4px_14px_rgba(0,0,0,0.04)]  */}
            <form onSubmit={handleOnSubmit}>
              <div className="mb-2 flex flex-col gap-2 md:flex-row">
                <div className="mb-2 flex-1">
                  <label>ชื่อจริง</label>
                  <input
                    type="text"
                    name="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
                <div className="mb-2 flex-1">
                  <label>นามสกุล</label>
                  <input
                    type="text"
                    name="companyDiscription"
                    value={companyDiscription}
                    onChange={(e) => setCompanyDiscription(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label>ที่อยู่สำหรับติดต่อ</label>
                <input
                  type="text"
                  name="companyAddress"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>

              <div className="mb-3">
                <label>โทรศัพท์</label>
                <input
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke border-[rgb(241 245 249)] bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              {/*              <div className="mb-4.5 flex flex-col gap-6 md:flex-row">
                <div className="mb-2 flex-1">
                  <label
                    htmlFor="file"
                    className="w-full flex gap-4 items-center"
                  >
                    <div className="w-full mb-2 rounded-lg overflow-hidden flex gap-4 item-center">
                      {fileName !== null ? (
                        <img
                          src={fileName}
                          className={`w-[150px] h-[150px] rounded-lg object-cover`}
                        />
                      ) : (
                        <IoImagesSharp size={50} />
                      )}
                      <input
                        type="file"
                        id="file"
                        onChange={imageChange}
                        accept="image/png, image/jpg, image/jpeg"
                        className="hidden"
                      />
                      รูปโปรไฟล์{" "}
                    </div>
                  </label>
                </div>
              </div> */}
              <div className="mb-4.5 flex flex-col gap-6 md:flex-row">
                <div className="mb-2 flex-1">
                  <label
                    htmlFor="file"
                    className="flex gap-4 items-center text-[#504f4f]"
                  >
                    <div className="flex flex-col items-start">
                      {fileName !== null ? (
                        <img
                          src={fileName}
                          className={`w-[150px] h-[150px] object-cover`}
                        />
                      ) : (
                        <IoImagesSharp size={50} color="#504f4f" />
                      )}
                      <input
                        type="file"
                        id="file"
                        onChange={handleOnChange}
                        className="hidden"
                      />
                      รูปโปรไฟล์หรือรูปหน้าร้าน
                    </div>
                  </label>
                </div>
              </div>
              {/* ----  */}

              {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
              <div className="flex justify-end">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="w-full text-center bg-sky-500 text-white py-2.5 rounded-sm"
                >
                  {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
                </button>
              </div>
            </form>
            {/* -----------  */}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default EditShop;
