import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";

import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  shopUrl,
} from "../constants/BaseUrl";
import axios from "axios";
// import MainOccupationCard from "./../components/MainOccupationCard";
import {
  MainOccupationCard,
  NearProviceCard,
  TopProviceCard,
  Header,
  DocCardList,
} from "../components";

const HomeContact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [docContactData, setDocContactData] = useState([]);
  const [countContactData, setCountContactData] = useState("");

  const readDocContact = (memId, who) => {
    axios
      .get(`${shopUrl}readcontacttoproviderow.php/${memId}/${who}`)
      .then(function (response) {
        // console.log(response.data);
        setDocContactData(response.data);
      });
  };
  useEffect(() => {
    var user = localStorage.getItem("pvid");
    setUserId(user);
    readDocContact(user, 0);
    /*     if (userId === null) {
      navigate("/signin");
    } else {
      navigate("/");
    } */
  }, [userId]);
  // <DocCardList key={`doc_${idx}`}/>
  // console.log("Contact: ", docContactData);
  return (
    <>
      <Header />
      <div className="overflow-y-scroll mx-2">
        <div className="mt-[5rem]">
          {Object.keys(docContactData).length !== 0 ? (
            <>
              {docContactData.map((item, idx) => {
                return (
                  <DocCardList
                    key={`doc_${idx}`}
                    item={item}
                    width={windowSize[0]}
                  />
                );
              })}
            </>
          ) : (
            <>
              <div
                className={`w-[${windowSize[0]}px] h-[${
                  windowSize[1] * 0.5
                }px] rounded-lg overflow-hidden relative`}
              >
                <img
                  src={require("../assets/images/no-8.png")}
                  alt={""}
                  className={`w-[${windowSize[0]}px] h-[${
                    windowSize[1] * 0.5
                  }px] object-cover`}
                />
                <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
                  <strong className="text-sm text-white">
                    ไม่มีรายการติดต่อ
                  </strong>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeContact;
